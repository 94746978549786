import React from 'react';

class ContactEviction extends React.Component {

    render() {
        return (
            <div className="container-fluid" id="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-sm-6">
                            <div className="contact-left-section">
                                <h2>Start Online - Eviction Services for Landlords Only</h2>
                                <p>Click the START NOW button. Choose the service you need, complete the online intake form, and submit payment to get started. Once the intake form is submitted, all documents are hand prepared by a Registered Unlawful Detainer Assistant (UDA). The documents are e-signed and returned to us for filing. Registered Unlawful Detainer Assistant prepares all necessary legal documents.</p>
                                <p>We strive to provide a streamlined process to our clients to fulfill all their legal document preparation needs in a quick and efficient manner.</p>
                                <h3>Get In Touch</h3>
                                <div className="call-section">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="calling-icon">
                                                <img src="https://ldaprolegal.com/wp-content/themes/SoftMindersTheme/assets/images/call-image1.png" className="img-fluid" alt=""/>
                                            </div>
                                            <div className="calling-text">
                                                <h4><a href="sms:(916) 620-2446?&body=Hi I might be interested in your service...">TEXT US</a></h4>
                                                <small className="text-white">Say Hi...</small>
                                            </div>
                                        </div>
                                        <span className="line"></span>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="calling-icon">
                                                <img src="https://ldaprolegal.com/wp-content/themes/SoftMindersTheme/assets/images/call-image2.png" className="img-fluid" alt=""/>
                                            </div>
                                            <div className="calling-text">
                                                <h4><a href="tel:1-844-453-2776">CALL US</a></h4>
                                                <small className="text-white">Toll Free</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-right-section">
                                <h2>Fill the form below to get information about our legal document services.</h2>
                                <form action="" method="post" data-netlify="true" name="CommonContact">
                                    <input type="hidden" value="CommonContact" name="form-name"></input>
                                    <div className="row">
                                        <div className="col-sm-6 ">
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Name *" type="text" name="Name" required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Phone *" type="text" name="Phone" required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Email *" type="text" name="Email" required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                            <textarea rows={10} maxLength={200} className="form-control" aria-invalid="false" placeholder="Message" name="Message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ContactEviction;
