import React, {useEffect} from "react";
import {Link} from "react-router-dom"
import ContactSection from '../../components/common/ContactSection'
import CTA from '../../components/common/CTA'

export const Deed = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <div className="container-fluid" id="inner-banner">
                    <div className="container">
                        <div className="row pt-5">
                            <div className="col-xl-12">
                                <div className="about-content">
                                    <div className="breadcrumbs">
                                        <div className="title">LDA PRO LEGAL</div>
                                        <h1>Deed Services</h1>
                                        <div className="text" id="txt">Deed Document Preparation in Sacramento, California and Surrounding Counties.</div>
                                        <p className="mt-2">
                                            <React.Fragment>
                                                <Link to="/services/deed-services/start-deed-services-online/" className="btn-style-three theme-btn" id="round-button">
                                                <span className="txt">START NOW</span></Link>
                                            </React.Fragment>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="container bg-white mb-5" id="seven-section">
                <div className="row">
                    <div className="col-xl-4 mb-5">
                        <div className="price-section-left">
                            <h2>GRANT DEED</h2>
                            <div className="price">$299</div>
                            <p>Grant Deed: Can be used when property is being sold from one person to another, or to transfer real property into a living trust.<br/>
                                Quitclaim Deed: A quitclaim deed can be used for the transfer of real property in California of an unrecorded or recorded interest from a grantor to a grantee.
                            </p>
                            <div className="btns-box mt-4 ml-3" id="button-box">
                                <Link className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/deed-services/start-deed-services-online/" id="round-button"><span className="txt">START NOW</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 mb-5">
                        <div className="price-section-left">
                            <h2>INTER-SPOUSAL TRANSFER DEED</h2>
                            <div className="price">$299</div>
                            <p>An “inter-spousal transfer deed” transfers title (ownership) between a married couple. A gift given by one spouse to the other during the marriage is considered “separate” (owned separately), not “marital” (mutually-owned) property.</p>
                            <div className="btns-box mt-4 ml-3" id="button-box">
                                <Link className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/deed-services/start-deed-services-online/" id="round-button"><span className="txt">START NOW</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 mb-5">
                        <div className="price-section-left">
                            <h2>TRANSFER ON DEATH DEED</h2>
                            <h4>PETITION</h4>
                            <div className="price">$299</div>
                            <p>Avoid probate and the expense of a Living Trust. If you are the only owner, or if your co-owner has already passed away, your beneficiary will receive the property. In order to put their name on the title, they notarize and record a simple form called Affidavit of Death of Transferor under TOD Deed, along with a death certificate.</p>
                            <div className="btns-box mt-4 ml-3" id="button-box">
                                <Link className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/deed-services/start-deed-services-online/" id="round-button"><span className="txt">START NOW</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2><strong>LDA Pro Legal: Your Trusted Partner for Deed Services in California</strong></h2>
                        <p>Transferring a deed in California can be a complex and time-consuming process. Whether you’re looking to transfer property ownership, add or remove a co-owner, or update your deed for estate planning purposes, LDA Pro Legal is here to simplify the process. With our expertise and online document processing services, we make deed services in California convenient, efficient, and hassle-free.</p>
                        <h3><strong>Understanding Deed Services in California</strong></h3>
                        <p>A deed transfer is a legal process that involves changing the ownership of a property from one party to another. California has specific laws and regulations governing deed services, and it’s crucial to adhere to these requirements to ensure a smooth and legally valid transfer. At LDA Pro Legal, we have in-depth knowledge of California’s deed transfer laws and stay up-to-date with any changes in legislation. Our experienced team is well-equipped to guide you through the process and help you navigate the legal complexities involved.</p>
                        <h3><strong>Efficient Online Document Processing</strong></h3>
                        <p>With our user-friendly online platform, LDA Pro Legal offers a convenient way to process your deed transfer documents online. Instead of dealing with the complexities of paperwork and visits to government offices, you can complete the necessary forms and provide us with the required information from the comfort of your own home or office. Our streamlined online process saves you time and effort, allowing you to initiate the deed transfer quickly and efficiently.</p>
                        <h3><strong>Customized Support Tailored to Your Needs</strong></h3>
                        <p>At LDA Pro Legal, we understand that every deed transfer case is unique. Whether you’re transferring a residential property, commercial property, or agricultural land, our experienced team provides personalized support tailored to your specific needs. We take the time to understand your circumstances and guide you through the appropriate steps and documentation required for a successful deed transfer. Our goal is to ensure that all necessary documents are prepared accurately and in compliance with California laws.</p>
                        <h3><strong>Expert Guidance and Legal Assistance</strong></h3>
                        <p>With LDA Pro Legal by your side, you have access to expert guidance and legal assistance throughout the deed transfer process. Our team consists of experienced legal document assistants who specialize in deed services in California. We are well-versed in the legal requirements, deadlines, and procedures involved in transferring deeds, and we can help you navigate the process with confidence and peace of mind.</p>
                        <h3><strong>Cost-Effective Solutions</strong></h3>
                        <p>We understand that deed services can involve significant costs, including attorney fees and other expenses. At LDA Pro Legal, we offer cost-effective solutions that provide exceptional value for your investment. By utilizing our online document processing services, you can save on expensive legal fees while still ensuring that your deed transfer documents are prepared accurately and professionally. We are committed to delivering high-quality services at affordable prices.</p>
                        <h3><strong>Confidentiality and Professionalism</strong></h3>
                        <p>Confidentiality and professionalism are at the core of our values at LDA Pro Legal. We handle your deed transfer case with the utmost discretion, respecting your privacy and the sensitive nature of the transaction. You can trust that your information is securely handled, and our team maintains the highest standards of professionalism and ethics throughout the process.</p>
                        <h3><strong>Contact LDA Pro Legal for Deed Services in California</strong></h3>
                        <p>When it comes to deed services in California, LDA Pro Legal is your trusted partner. Our online document processing services, combined with our legal expertise, make the process convenient and efficient. Contact us today to discuss your deed transfer needs and let us guide you through the process with professionalism and efficiency. With LDA Pro Legal, you can confidently transfer your deed and ensure a legally valid and smooth transition of property ownership. <Link to="https://goo.gl/maps/6D2igryyqT57jVDXA">Visit Us Today!</Link></p>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-white" id="lda-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-left-section">
                            <img src={require("../../assets/images/service/deed/side-view-man-working-as-real-estate-agent_23-2151065017.jpg")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-right-section">
                            <h2>DEED SERVICES</h2>
                            <p>Transferring real estate title in California, is a straightforward process accomplished through the use of a property deed. After you have determined the right type of deed for your transaction, it is extremely important that the deed is prepared by professionals to avoid cloud on title. Simply complete our online intake form and we handle the rest. We pull the property’s title and make sure the new deed is prepared accurately, according to your needs. Once we have successfully prepared the deed, you will need to get the deed notarized and recorded at the County Recorder’s Office.</p>
                            <Link to="tel:1-844-453-2776" className="btn appointment-button">Schedule An Appointment Today <strong> 1-844-4LDAPRO</strong></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 bg-white" id="eviction-section">
                <div className="row">
                    <div className="col-xl-12 text-center">
                        <div className="separator">
                            <div className="line"></div>
                            <h2>TYPE OF DEEDS</h2>
                            <div className="line"></div>
                        </div>
                        <p>Legal Document Assistants Specializes In Reliable,</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/deed/deed1.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>GRANT DEED</h2>
                                <p>Because of inclusion of the word “grant” in a grant deed, the grantor impliedly warrants that he or she has not already conveyed to any other person and that the estate conveyed is free from encumbrances done, made or suffered by the grantor or any person claiming under grantor, including taxes, assessments and other liens. This does not mean that the grantor warrants that grantor is the owner or that the property is not otherwise encumbered. The grant includes appurtenant easements for ingress and egress and building restrictions. The grantor’s warranty includes encumbrances made during grantor’s, but no other individual’s, possession of the property. It conveys any title acquired after the grantor has conveyed the title to the real property (after-acquired title), generally. Observe that these warranties carried by a grant deed are not usually expressed in the grant deed form. They are called “implied warranties” because the law deems them included in the grant whether or not explicitly expressed in the deed</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/deed/deed12.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>QUITCLAIM DEED</h2>
                                <p>A quitclaim deed is a deed by which a grantor transfers only the interest the grantor has at the time the conveyance is executed. There are no implied warranties in connection with a quitclaim deed. This type of deed guarantees nothing and there is no expressed or implied warranty that grantor owns the property or any interest in it. Moreover, a quitclaim deed does not convey any after-acquired title. A quitclaim deed effectively says, “I am conveying all the title that I have in the property described in this quitclaim – if I have, in fact, any title.” A quitclaim deed is generally used to clear some “cloud on the title.” A “cloud on the title” is some minor defect in the title which needs to be removed in order to perfect the title. Deeds of court representatives, such as guardians, administrators, and sheriffs, usually have the effect of a quitclaim pursuant to court order.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/deed/12365.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>WARRANTY DEED</h2>
                                <p>A warranty deed contains express covenants of title. Warranty deeds are uncommon in California, no doubt because of the almost universal reliance in this state on title insurance to evidence marketable title.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/deed/deed1ab.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>TRUST DEED</h2>
                                <p>A trust deed (or deed of trust) is a 3-party security instrument conveying title to land as security for the performance of an obligation. There are three parties to a trust deed: borrower (trustor), lender (beneficiary), and a third party, called a trustee, to whom legal title to the real property is conveyed. The trustee holds the legal title in trust for the beneficiary and has the power to sell the property if the trustor does not fulfill the obligations as recited in the instrument. The trustee also possesses power to reconvey the legal title to the trustor provided the beneficiary requests a reconveyance of that title. This event occurs if the promissory note is paid in full. A trustor signing the trust deed retains what is called an equitable title. That is, the trustor enjoys the right of possession and can do with the property whatever the trustor pleases so long as the trustor does not jeopardize the interest of the lender (beneficiary). Business and Professions Code Section 10141.5 requires that a real estate licensee record a deed of trust within one week after closing of a transaction or deliver it to the beneficiary with a written recommendation that it be recorded or deliver it to the escrow holder. Failure of a real estate licensee to carry out the duties prescribed in Section 10141.5 does not affect the validity of the transfer of title to the real property.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/06/deed5.jpeg" className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>RECONVEYANCE DEED</h2>
                                <p>A reconveyance deed is an instrument conveying title to property from a trustee back to the trustor on termination of the trust. This title is held by the trustee until the note or obligation is fully paid. Then, when the beneficiary issues a “Request for Full Reconveyance,” the trustee executes the reconveyance to the borrower. Termination of the trust usually occurs when the promissory note is paid in full.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/deed/deed145.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>SHERIFF’S DEED</h2>
                                <p>A sheriff’s deed is a deed given to a party on the foreclosure of property, levied under a judgment for foreclosure on a mortgage or of a money judgment against the owner of the property. The title conveyed is only that acquired by the state or the sheriff under the foreclosure and carries no warranties or representations whatsoever.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/deed/deed14566.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>GIFT DEED</h2>
                                <p>A grantor may make a gift of property to the grantee, and use a grant deed form or a quitclaim deed form for the purpose. Grantor may, but need not, say in the deed that grantor makes the transfer because of love and affection for the grantee. A gift deed made to defraud creditors may be set aside if it leaves the debtor/grantor insolvent or otherwise contributes to fraud. (Uniform Fraudulent Transfer Act, Civil Code Sections 3439 through 3439.12)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <div className="container-fluid mt-5 bg-white" id="who_are_section">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section-box">
                            <div className="row">
                                <div className="col-xl-6 order-2 order-xl-1 order-md-1">
                                    <div className="left_section">
                                        <h2>CALIFORNIA DEED SERVICES</h2>
                                        <p>When properly executed, delivered, and accepted, a deed services title to real property from one person (the grantor) to another person (the grantee). Transfer may be voluntary, or involuntary by act of law, such as a foreclosure sale.</p>
                                        <p><strong>There Are Several Different Essentials To A Valid Deed:</strong></p>
                                        <ul>
                                            <li>It must be in writing;</li>
                                            <li>The parties must be properly described;</li>
                                            <li>The parties must be competent to convey and capable of receiving the grant of the property;</li>
                                            <li>The property conveyed must be described so as to distinguish it from other parcels of real property;</li>
                                            <li>There must be a granting clause, operative words of conveyance (e.g., “I hereby grant”);</li>
                                            <li>The deed must be signed by the party or parties making the conveyance or grant; and</li>
                                            <li>It must be notarized and recorded.</li>
                                        </ul>
                                        <p><Link id="round-button" className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/deed-services/start-deed-services-online/"><span className="txt">START NOW</span></Link></p>
                                    </div>
                                </div>
                                <div className="col-xl-6 order-1 order-xl-2 order-md-2">
                                    <div className="right_section">
                                        <img src={require("../../assets/images/service/deed/image-51.jpg")} className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <br/>
            <br/>
            <ContactSection/>
            <section>
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="faq-section">
                                <h2>
                                    Frequently Asked Questions	
                                </h2>
                                <div className="faq scroll scroll1">
                                    <div>
                                        <div className="question">
                                        1. What are deed services, and how can you help?
                                        </div>
                                        <div className="answer">
                                            <p>Deed services involve preparing, filing, and recording legal documents that transfer property ownership. We assist with drafting deeds, ensuring accuracy, and filing them with the appropriate local authorities to ensure a seamless process.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        2. What types of deeds do you prepare?
                                        </div>
                                        <div className="answer">
                                            <p>We handle a variety of deed types, including: Grant Deeds Quitclaim Deeds Warranty Deeds Transfer on Death Deeds Our team will guide you in selecting the right deed for your needs.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        3. Why would I need a deed transfer?
                                        </div>
                                        <div className="answer">
                                            <p>You may need a deed transfer when: Adding or removing someone from property ownership. Transferring property to family members or a living trust. Selling or gifting property. Correcting errors on an existing deed. </p>	
                                        </div>
                                        <div>
                                            <div className="question">
                                            4. What information do you need to prepare a deed?
                                            </div>
                                            <div className="answer">
                                                <p>To prepare a deed, we typically need: A copy of the current deed. Property details, including address and legal description. Information about the grantor (current owner) and grantee (new owner). Specific instructions regarding the type of transfer.</p>	
                                            </div>
                                            <div>
                                                <div className="question">
                                                5. How much do your deed services cost?
                                                </div>
                                                <div className="answer">
                                                    <p>Our deed services are competitively priced and tailored to your specific needs. Contact us for a free consultation to receive a detailed cost estimate.</p>	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-4">
                            <div className="faq-image">
                                <img src={require("../../assets/images/service/deed/deed_img04.png")} className="img-fluid" alt=""/>	
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}