import React, {useEffect} from "react";
import {Link} from 'react-router-dom'

export const Services = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <div className="container bg-white mt-5" id="services_section">
                <div className="row">
                    <div className="col-xl-12 text-center">
                        <div className="separator">
                            <div className="line"></div>
                            <h2>Legal Document Services</h2>
                            <div className="line"></div>
                        </div>
                        <p>Registered Legal Document Assistants</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-sm-4 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/service/businessman-reading-contract-closeup_1098-14742.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/service/icon-2.webp")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-content">
                                <h2>Eviction</h2>
                                <p>Landlords Utilize Our Services for Eviction Notice and  Unlawful Detainer Document Preparation, up until the Sheriff Performs the Lockout.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/eviction/" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-4 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/service/business-people-signing-contract_1098-21026.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/service/icon-3.webp")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-content">
                                <h2>PROBATE</h2>
                                <p>Providing Affordable Probate Document Preparation to Finalize a Person's Legal and Financial Affairs After Death.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/probate/" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-4 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/service/black-woman-pointing-document-near-lady-with-pen-table-with-calculator-gavel_23-2148042590-1.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/service/icon-4.webp")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-content">
                                <h2>LIVING TRUST</h2>
                                <p>Living Trust Package Includes: Revocable Living Trust, Trust Cert, Will, Healthcare Directive, Power of Attorney, Trust Transfer Deed, and Funding Instructions. </p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="#" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-2"></div>
                    <div className="col-xl-4 col-sm-4 mt-3 mb-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/service/deed/happy-young-asian-couple-realtor-agent_7861-1032.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/service/deed-transfer-service-icno.webp")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-content">
                                <h2>Deed Services</h2>
                                <p>Transferring real estate title in California, is a straightforward process accomplished through the use of a property deed. After you have determined the right type of deed for your transaction, it is extremely important that the deed is prepared by professionals to avoid cloud on title. Simply complete our online intake form and we handle the rest. We pull the property’s title and make sure</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="#" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/*
                    
                    <div className="col-xl-4 col-sm-4 mt-3 mb-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/service/small-claims.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/service/icon-6.webp")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-content">
                                <h2>SMALL CLAIMS</h2>
                                <p>Process Servers have an assortment of tasks. These tasks consist of filing court papers, serving legal documents, and document retrieval. Their main job, however, is to deliver “serve” legal documents to a party involved in a court case. These papers can consist of things like: Complaints, Petitions, Writs, Subpoenas, Restraining Orders, and Unlawful Detainers. </p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/small-claims/" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="col-xl-4 col-sm-4 mt-3 mb-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/service/image-b.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/service/DivorceIcon.png")} className="img-fluid" alt=""/>
                            </div>
                            <div className="service-content">
                                <h2>DIVORCE</h2>
                                <p>Appointing a lawful process server is an essential step in proceeding with a court case. If you are serious about your case, you have to hire a process server. One important thing you should keep in your mind that process server and lawyer are not the same people and their functions are not the same. Their job and position are different. A solicitor will fight for you; on the other hand, a process server will help you prepare your legal document and they deliver all legal documents to each party.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/divorce" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
