import React, {useEffect} from "react";


export const StartDivorce = (): React.ReactElement<any, any> => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://form.jotform.com/jsform/223647694366469";
        script.async = true;
        document.getElementById("jotform-container")?.appendChild(script);
    }, []);

    return (
        <div className="container" id="eviction-start">
            <div id="jotform-container"></div> {/* JotForm will load here */}
        </div>
    );
}

