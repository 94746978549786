import React, {useEffect,useState} from "react";
import { Link } from "react-router-dom"
import axios from 'axios'; // Axios for HTTP requests

interface HtmlDecoderProps {
    html: string; // Define the type of the html prop
}

const HtmlDecoder: React.FC<HtmlDecoderProps> = ({ html }) => {
    const decodeHtmlEntities = (html: string): string => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;//HTML Entity Decode
        const htmlwithtag = txt.value;
        const removedtaghtml = htmlwithtag.replace(/<\/?[^>]+(>|$)/g, "");//Removing HTML Tags
        return removedtaghtml.substring(0, 100) + '...';//Trimming to 50 characters
    };

    const decodedHtml = decodeHtmlEntities(html);

    return (
        <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />
    );
};

export const Blogs = (): React.ReactElement<any, any> => {
    
    type Blog = {
        slug: string;
        seo_title: string;
        seo_description: string;
        seo_keywords: string;
        name: string;
        featured_image: string;
        content: string;
        created_at: string;
    };
    const [posts, setPosts] = useState<Blog[]>([]); // Explicitly set type to Area[]
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://server.ldaprolegal.com/api/v2/get-all-posts');
                // Make sure response.data is an array before setting it to posts
                if (Array.isArray(response.data)) {
                    setPosts(response.data);
                } else {
                    console.error("Expected an array but got:", response.data);
                }
            } catch (error) {
                console.error(error); // Log error if request fails
            }
        };

        fetchData();
    }, []);
    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric"
        });
    };
    

    return (
        <>
            <div className="container mt-5" id="blog-section">
                <div className="row">
    		        <div className="col-xl-12 text-center">
    			        <div className="separator">
                            <div className="line"></div>
                            <h2>LATEST NEWS</h2>
                            <div className="line"></div>
                        </div>
                        <p>Legal Document Assistants Specialize In Estate Document Preparation Services.</p>
                    </div>
                </div>
                <div className="row mt-5">
                    {(posts || []).map((post, index) => ( 
                        <div className="col-xl-4 col-sm-6">
                            <div className="main-blog-box">
                                <div className="blog-thumbnail">
                                    <Link to={`/${post.slug}`}><img src={`${post.featured_image}`} className="img-fluid" alt=""/></Link>
                                </div>
                                <div className="blog-content">
                                    <h3><Link to={`/${post.slug}`}>{post.name}</Link></h3>
                                    <HtmlDecoder html={post.content} />
                                    <hr/>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                    </svg>{formatDate(post.created_at)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
