import React, {useEffect} from "react";
import {Link} from "react-router-dom"
import ContactEviction from '../../components/common/ContactEviction'
import CTA from '../../components/common/CTA'

export const Eviction = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <div className="container-fluid" id="inner-banner">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-xl-12">
                            <div className="about-content">
                                <div className="breadcrumbs">
                                    <div className="title">LDA PRO LEGAL</div>
                                    <h1>Evict Tenants Without <br/>Expensive<span className="theme-color"> Legal Fees</span></h1>
                                    <div className="text" id="txt">Registered Unlawful Detainer Assistants (UDAs), providing eviction services throughout Northern California.</div>
                                    <p className="mt-2">
                                        <React.Fragment>
                                            <Link to="/services/eviction/start-eviction-online/" className="btn-style-three theme-btn" id="round-button"><span className="txt">START NOW</span></Link>
                                        </React.Fragment>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 bg-white mb-5" id="eviction-section">
                <div className="row">
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/eviction/eviction1.jpg")} className="img-fluid" alt="EVICTION NOTICE"/>
                            </div>
                            <div className="eviction-content">
                                <h2>EVICTION NOTICE</h2>
                                <h4>Registered Unlawful Detainer Assistants </h4>
                                <h4>Prepare a Notice that complies with California Law.</h4>
                                <div className="price text-center">$99</div>
                                <ul style={{textAlign: "center"}}>
                                    <li><i className="fa fa-check"></i> 3 Day Notice to Pay Rent or Quit</li>
                                    <li><i className="fa fa-check"></i> 3 Day Notice to Pay Utilities or Quit</li>
                                    <li><i className="fa fa-check"></i> 3 Day Notice to Perform Covenant or Quit</li>
                                    <li><i className="fa fa-check"></i> 3 Day Notice to Quit</li>
                                    <li><i className="fa fa-check"></i> 30 Day Notice to Vacate</li>
                                    <li><i className="fa fa-check"></i> 60 Day Notice to Vacate</li>
                                    <li><i className="fa fa-check"></i> 90 Day Notice to Vacate</li>
                                    <li><i className="fa fa-check"></i> 120 Day Notice to Vacate</li>
                                    <li><i className="fa fa-check"></i> 5 Day Squatter Notice</li>
                                </ul>
                            </div>
                            <div className="col-sm-12 mt-3 text-center">
                                <p><Link id="round-button" className="btn-style-three theme-btn" to="/services/eviction/start-eviction-online/"><span className="txt">Start Now</span></Link></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/eviction/eviction_img11.png")} className="img-fluid" alt="LANDLORD EVICTION SERVICE"/>
                            </div>
                            <div className="eviction-content">
                                <h2>EVICTION COURT</h2>
                                <h4>Registered Unlawful Detainer Assistants </h4>
                                <h4>Prepare, File, and Serve Court Documents.</h4>
                                <div className="price text-center">$799</div>
                                <p>Once the Notice is served and the tenants do not comply with the notice, you are ready to proceed with the eviction process.</p>
                                <p>We prepare, file, and serve all the necessary court forms, from start to finish for an uncontested Eviction, for a flat fee, plus mandatory court filing fees.</p>
                                <p>If the other party responds you can either represent yourself in court or hire one of our cooperating attorneys to represent you in court, at a discounted rate.</p>
                            </div>
                            <div className="col-sm-12 mt-3 text-center">
                                <p><Link id="round-button" className="btn-style-three theme-btn" to="/services/eviction/start-eviction-online/"><span className="txt">Start Now</span></Link></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/eviction/brunette-businesswoman-writing-document_23-2148142696.jpg")} className="img-fluid" alt="EVICTION NOTICE PROCESS"/>
                            </div>
                            <div className="eviction-content">
                                <h2>EVICTION NOTICE PROCESS</h2>
                                <p>The Notice is the most critical part of the entire eviction process. It is important that it is prepared and served according to California law. LDA Pro Legal recommends that you hire us to prepare the Notice for $99.</p>
                                <h4 className="text-center">STEP 1 - PREPARE THE NOTICE</h4>
                                <ul>
                                    <li>Complete the intake form online and submit payment. After the intake form is submitted, Registered Unlawful Detainer Assistants prepare the Eviction Notice and email it to the client for e-signature.</li>
                                </ul>
                                <h4 className="text-center">SERVE THE NOTICE</h4>
                                <ul>
                                    <li>Our Registered Process Server, will serve the Notice to all occupants for $149. LDA Pro Legal recommends hiring a Registered Process Server to serve the Eviction Notice to all occupants. Service details and next steps are emailed.  Anyone over the age of 18 is allowed to serve the Eviction Notice by personal service or posting AND mailing USPS standard mail. The server completes and signs the proof of service. Once the notice expires, you can proceed with filing the Eviction and Unlawful Detainer.</li>
                                </ul>
                                <p><img className="alignnone wp-image-8709 size-full" src={require("../../assets/images/service/eviction/Professional-Experienced-LDA-PRO-LEGAL.jpeg")} alt="" width="413" height="416"  /></p>
                            </div>
                            <div className="col-sm-12 mt-3 text-center">
                                <p><Link id="round-button" className="btn-style-three theme-btn" to="/services/eviction/start-eviction-online/"><span className="txt">Start Now</span></Link></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/eviction/man-office-writing-paper_23-2148377785.jpg")} className="img-fluid" alt="EVICTION UNLAWFUL DETAINER PROCESS"/>
                            </div>
                            <div className="eviction-content">
                                <h2>EVICTION COURT PROCESS</h2>
                                <ul>
                                    <li>Once the tenants or occupants have not complied with the Notice, a Landlord may proceed with the Eviction Court process. Complete the intake form online and submit payment for $799. </li>
                                    <li>Plus Mandatory Court Filing Fees $240.</li>
                                </ul>
                                <h4>STEP 2 - EVICTION COURT DOCUMENTS</h4>
                                <ul>
                                    <li>We prepare the eviction court forms for e-signature.</li>
                                    <li>We file the unlawful detainer with the court and serve the Defendants and All Other Occupants.</li>
                                    <li>Once the Defendants are served, we inform you exactly how many days they have to Respond.</li>
                                    <li>If the Defendant doesn’t Respond, we request for Entry of Default Judgement and the Writ of Possession.</li>
                                    <li>We deliver the Writ to the Sheriff’s Department and schedule the lockout.</li>
                                    <li>If the Defendants Respond, we Set for Hearing.</li>
                                    <li>If you need Attorney Representation at the Hearing, our Cooperating Attorney will represent you for a discounted fee.</li>
                                </ul>
                                <p><img className="alignnone wp-image-9373 size-full" src={require("../../assets/images/service/eviction/image-ecict.jpg")} alt="" width="413" height="416"/></p>
                            </div>
                            <div className="col-sm-12 mt-3 text-center">
                                <p><Link id="round-button" className="btn-style-three theme-btn" to="/services/eviction/start-eviction-online/"><span className="txt">Start Now</span></Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactEviction/>
            <div className="container-fluid" id="">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-box">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="left_section">
                                            <h2>Eviction Services in California</h2>
                                            <div className="right_section d-block d-xl-none d-md-none d-sm-none">
                                                <img src={require("../../assets/images/service/eviction/closeup-eviction-notice-document-with-gavel-resting-top-symbolizing-finality-weight-legal-process-scaled.jpg")} className="img-fluid" alt="Eviction Services in California"/>
                                            </div>
                                            <p>If you’re a landlord and own residential rental property or commercial rental property, you have a lot of responsibilities like maintaining the property and completing any emergency repairs that may arise. You also have to collect rent and utility payments from your tenants. If your tenants are not paying their rent or are not abiding by the conditions in the rental agreement, you have grounds to evict tenants. You may also evict a tenant, for other no fault just cause reasons, according to California law.</p>
                                            <p>The eviction process can be a difficult process if you are not familiar with the legality of eviction. It is important that you hire an experienced Unlawful Detainer Assistant or Landlord Eviction Attorney who is familiar with California laws, as well as the local laws in the city and county the property is located in. Reach out to LDA PRO Legal Document Assistants. We help you prepare, file, and serve all the necessary documents required throughout the entire eviction process for a flat fee. Pay as you go for the services you need.</p>
                                            <div className="btns-box mt-5">
                                                <Link className="btn-style-three theme-btn" to="/services/eviction/start-eviction-online/" id="round-button"><span className="txt">Start Now</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 d-none d-xl-block d-md-block">
                                        <div className="right_section">
                                            <img src={require("../../assets/images/service/eviction/closeup-eviction-notice-document-with-gavel-resting-top-symbolizing-finality-weight-legal-process-scaled.jpg")} className="img-fluid" alt="Eviction Services in California"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid four" id="legal-service-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h2 className="color-white">CONTESTED EVICTION</h2>
                            <h4 style={{color:"#fff"}}>Do You Want to Be Represented in Court by a Landlord Eviction Attorney?</h4>
                            <p style={{color:"#fff"}}>If the case becomes contested we prepare all the trial documents if the case must be set for trial. You also have the option to hire a cooperating attorney, within our attorney referral network, to represent you in court or you can choose to represent yourself in court, should the eviction become contested. Fee above includes the attorney to appear along with the Plaintiffs at trial and represent you at the hearing. Attorney’s rates may vary depending on location or circumstance.</p>
                            <div className="bold-title">
                                <h2 className="color-white">COOPERATING EVICTION ATTORNEY + $399</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container bg-white" id="lda-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-left-section">
                            <img src={require("../../assets/images/service/eviction/lda-eviction-2-1.jpg")} className="img-fluid" alt="WE ASSIST LANDLORDS FROM START TO FINISH DURING THE EVICTION PROCESS"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-right-section">
                            <h2>SHERIFF LOCKOUT FEES</h2>
                            <p>If the Defendants vacate the property after being served, you will not need this step. To enforce the lockout the landlord can deliver the Writ of Possession and Lockout Instructions to the Sheriff's Office to proceed with scheduling the lockout. The Sheriff charges $180 for the lockout. If you prefer to have LDA Pro Legal prepare and file the sheriff instructions and schedule the lockout, we charge $299.</p>
                            <p>Sheriff Lockout Agent: You can appoint yourself or someone as your agent to meet the sheriff to change the locks. If you prefer, you can hire us for Full Service Lockout Agent Services. LDA Pro Legal will prepare and file the Sheriff Instructions, as well as meet the Sheriff and change the locks for $898.</p>
                            <p>We assist landlords from start to finish, with trustworthy, reliable service.</p>
                            <h3>LOCAL EVICTION SPECIALIST, 10+ YEARS IN BUSINESS, AND AFFORDABLE FLAT RATES. PAY AS YOU GO FOR THE SERVICES YOU NEED. </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div id="provisions-part">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 order-2 order-xl-1">
                            <div className="provisions-section">
                                <h2>Just Cause Provisions</h2>
                                <p style={{color:"#000"}}>Beginning on January 1st, 2020, California Civil Code 1946.2 states that after 12 months, a tenancy can only be terminated for a specific set of reasons. Under the statewide law, there are two different types of just cause eviction: At-Fault and No-Fault. Per local City and County rules, certain types of properties are exempt from just cause eviction rules, however, including a just cause reason is always suggested by the court. Eviction laws change consistently, so it is important to include a just cause reason on the notice. Some common At Fault Just Cause Reasons include:</p>
                                <p style={{color:"#000"}}>Tenant not paying rent, tenant not complying with terms of the rental agreement, or tenants causing health and safety issues. Some common No Fault Just Cause Reasons include: Owner or Owner’s Family Member Moving In, Withdrawing from the Rental Market for 12 months, or Owner Intends to Demolish or Substantially Remodel the Premises. The last month’s rent should be waived for most No-Fault Just Cause Reasons, for tenants who have occupied for more than 12 months. It is critical for all notices to have all the required California laws included in the notice and that the notice is served properly, prior to starting the eviction process.</p>
                            </div>
                        </div>
                        <div className="col-xl-5 order-1 order-xl-2">
                            <div className="provisions-image">
                                <img src={require("../../assets/images/service/eviction/high-angle-hands-signing-documents_23-2149445767.jpg")} className="img-fluid" alt="Just Cause Provisions"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container bg-white" id="lda-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-left-section">
                            <img src={require("../../assets/images/service/eviction/person-conducting-online-psychologist-therapy_23-2149407951.jpg")} className="img-fluid" alt="Terminate Tenancy"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-right-section">
                            <h2>Terminate Tenancy</h2>
                            <p>Termination of Tenancy can be done many ways, depending on the circumstances. The first step and most critical part of the entire process is preparing and serving the notice accurately. Landlords must be sure to not do anything that will make the notice invalid. Common at fault notices that are served include: 3 Day Notice to Pay Rent or Quit, 3 Day Notice to Perform a Covenant or Quit, and 3 Day Notice to Quit. Common no fault notices that are served include: 30 Day Notice, 60 Day Notice, 90 Day Notice, or 120 Day Notice. Once the tenants are properly served with the correct notice and the tenants have failed to comply with the notice, once the notice has expired, the landlord can file a lawsuit for an Unlawful Detainer.</p>
                            <p>Above all, we always highly recommend that all landlords hire a professional to prepare and serve the notice to vacate.<br/>
                                A landlord should never rely on a notice that has not been prepared and served by a professional. It is very important that a landlord understands the negative impact a faulty notice to vacate can have on the entire eviction process. In most cases landlords will have to re-file the entire complaint and must start the entire process all over again.
                            </p>
                            <p>Even if a landlord is knowledgeable preparing the required court forms, they may not be up to date with current laws.</p>
                            <p>Hiring LDA PRO for Eviction Services, will save you time, money, and heartache!</p>
                            <div className="btns-box mt-4">
                                <Link className="btn-style-three theme-btn" to="/services/eviction/start-eviction-online/" id="round-button"><span className="txt">Start Now</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container-fluid five" id="five-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h2>Terminate Tenancy</h2>
                            <p>Termination of Tenancy can be done many ways, depending on the circumstances. The first step and most critical part of the entire process is preparing and serving the notice accurately. Landlords must be sure to not do anything that will make the notice invalid. Common at fault notices that are served include: 3 Day Notice to Pay Rent or Quit, 3 Day Notice to Perform a Covenant or Quit, and 3 Day Notice to Quit. Common no fault notices that are served include: 30 Day Notice, 60 Day Notice, 90 Day Notice, or 120 Day Notice. Once the tenants are properly served with the correct notice and the tenants have failed to comply with the notice, once the notice has expired, the landlord can file a lawsuit for an Unlawful Detainer.</p>
                            <p>Above all, we always highly recommend that all landlords hire a professional to prepare and serve the notice to vacate. A landlord should never rely on a notice that has not been prepared and served by a professional. It is very important that a landlord understands the negative impact a faulty notice to vacate can have on the entire eviction process. In most cases landlords will have to re-file the entire complaint and must start the entire process all over again.</p>
                            <p>Even if a landlord is knowledgeable preparing the required court forms, they may not be up to date with current laws.</p>
                            <p>Hiring LDA PRO for Eviction Services, will save you time, money, and heartache!</p>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <CTA/>
            <br/>
            <br/>
            <div className="container eviction-bottom-section">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="eviction-thumbnail p-3 eviction-otr">
                                    <img src={require("../../assets/images/service/eviction/Eviction_001.jpg")} className="img-fluid" alt="Just Cause Provisions"/>
                                    <h2><strong>LDA Pro Legal: Your Trusted Partner for Eviction Services in California</strong></h2>
                                    <p>With our team of Registered Unlawful Detainer Assistants and Experienced Landlord Attorneys, you can rest assured you're in good hands. When it comes to the eviction process in California, navigating the legal requirements and paperwork can be a complex and time-consuming endeavor. That’s where LDA Pro Legal steps in. We are your trusted partner, specializing in eviction services to assist landlords and property owners in California. With our expertise and online document processing, we streamline the eviction process, making it more efficient and hassle-free.</p>
                                    <h3><strong>Understanding the Eviction Process in California</strong></h3>
                                    <p>Evicting a tenant can be a daunting task, but it is sometimes necessary to protect your property rights. However, California has specific laws and regulations in place to ensure that both landlords and tenants are treated fairly throughout the process. At LDA Pro Legal, we have a thorough understanding of these laws and the eviction process in California. We stay up-to-date with any changes in legislation to provide you with accurate and reliable guidance.</p>
                                    <h3><strong>Efficient Online Document Processing</strong></h3>
                                    <p>We understand that time is of the essence when it comes to eviction cases. That’s why we offer online document processing services, allowing you to initiate the eviction process quickly and conveniently. With our user-friendly online platform, you can fill out the necessary forms and provide us with the required information from the comfort of your own home or office. Our streamlined process helps expedite the preparation and filing of eviction documents, saving you valuable time and effort.</p>
                                    <h3><strong>Customized Support Tailored to Your Needs</strong></h3>
                                    <p>At LDA Pro Legal, we recognize that each eviction case is unique. Our experienced team takes the time to understand your specific circumstances and tailor our services accordingly. Whether you need assistance with an unlawful detainer notice, eviction summons and complaint, or other eviction-related documents, we provide customized support to meet your needs. We strive to ensure that all necessary documents are prepared accurately and in compliance with California laws.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="eviction-thumbnail p-3 eviction-otr">
                                <img src={require("../../assets/images/service/eviction/eviction_002.jpg")} className="img-fluid" alt="Just Cause Provisions"/>
                                    <h3><strong>Professional Guidance and Legal Expertise</strong></h3>
                                    <p>With LDA Pro Legal by your side, you can have peace of mind knowing that you have professional guidance and legal expertise throughout the eviction process. Our team consists of experienced legal document assistants who have extensive knowledge of eviction laws in California. We are well-versed in the legal requirements, deadlines, and procedures involved in evictions, and we can help you navigate the process smoothly.</p>
                                    <h3><strong>Cost-Effective Solutions</strong></h3>
                                    <p>We provide Flat Fee eviction services. Pay as you go, for only the services you need. We understand that eviction cases can be financially challenging for landlords and property owners. That’s why we offer cost-effective solutions that provide exceptional value for your investment. By utilizing our online document processing services, you can save on expensive attorney fees while still ensuring that your eviction documents are prepared accurately and professionally. We are committed to delivering high-quality services at affordable prices.</p>
                                    <h3><strong>Confidentiality and Professionalism</strong></h3>
                                    <p>At LDA Pro Legal, we prioritize confidentiality and professionalism. We handle your eviction case with the utmost discretion, respecting your privacy and the sensitive nature of the situation. You can trust that your information is securely handled, and our team maintains the highest standards of professionalism and ethics.</p>
                                    <h3><strong>Contact LDA Pro Legal for Eviction Services in California</strong></h3>
                                    <p>When you need reliable eviction services in California, turn to the experts at LDA Pro Legal. Our online document processing services, combined with our legal expertise, make the eviction process more efficient and convenient. Contact us today to discuss your eviction case and let us guide you through the process with professionalism and efficiency. With LDA Pro Legal, you can take the necessary steps to protect your property rights effectively. <Link to="https://goo.gl/maps/6D2igryyqT57jVDXA">Visit Us Today!</Link></p>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        <br/>
                        <br/>
                        <h2 style={{marginBottom:"20px" , textAlign: 'center'}}>Eviction Service Fees</h2>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="eviction-thumbnail p-3 eviction-otr">
                                    <img src={require("../../assets/images/service/eviction/eviction_003.jpg")} className="img-fluid" alt="Just Cause Provisions"/>
                                    <h3>How Much Does the Eviction Process Cost to Start?</h3>
                                    <h4 style={{fontSize: "20px"}}>Eviction Notice: $99</h4>
                                    <p>LDA Pro Legal allows you to pay as you go and there are no hidden fees.<br/>
                                        LDA Pro Legal Prepares the Notice to all Tenants and Unnamed Occupants, within 1-2 business days. We email the notice for e-signature and email service details and next steps. If you select to have more than one type of notice served, each additional notice is $99 each. For example, serving a 3 Day Notice to Pay Rent and 60 Day Notice, Same Day, Same Time, Same Manner.
                                    </p>
                                    <p>We charge $149 to serve the Notice to All Tenants and Occupants. We email service details, after the notice has been served. We email you instructions as to what to do and what not to do, so that you do not make the notice invalid. Once the notice expires and you notify us the tenants have not complied with the notice, we proceed with the next step, preparing the Eviction and Unlawful Detainer in Court.</p>
                                    <h3>How Much Does the Eviction and Unlawful Detainer Process Cost, from Start to Finish?</h3>
                                    <h4 style={{fontSize: "20px"}}>Eviction Unlawful Detainer Fees: $799</h4>
                                    <p>LDA PRO Legal Prepares, Files, and Serves the Unlawful Detainer to Tenant and Unnamed Occupants. Once the notice expires and tenants have not complied with the notice, you may proceed with the eviction process.</p>
                                    <h4 className="mt-2" style={{fontSize: "20px"}}>Plus Mandatory Court Filing Fees</h4>
                                    <p>Card Authorization Form is Included in Documents and Charged Upon Filing.</p>
                                    <h4 style={{fontSize: "20px"}}>+$240 – Initial Court Filing Fee</h4>
                                    <h4 style={{fontSize: "20px"}}>+$40 – Writ of Possession Court Fee</h4>
                                    <h4 className="mt-2" style={{fontSize: "20px"}}>Service of Process Fees</h4>
                                    <p>$40 fee will be applied, for each additional tenant, that needs to be served. Registered process servers will make up to 5 attempts. If the tenants are not able to be served within 5 attempts, we must apply for an Order to Post and Mail, for an additional $299.</p>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="eviction-thumbnail p-3 eviction-otr">
                                <img src={require("../../assets/images/service/eviction/eviction_004.jpg")} className="img-fluid" alt="Just Cause Provisions"/>
                                    <h3>After the Case Is Filed, What If the Tenants File an Answer and the Case Becomes Contested?</h3>
                                    <h4 style={{fontSize: "20px"}}>Cooperating Attorney Representation :+$399 (Optional)</h4>
                                    <p>If the tenants contest the case in court you have the option of representing yourself at the hearing or you can hire our cooperating attorney to represent you for a discounted rate. You can decide later if you want to hire our cooperating attorney to represent you, once the case becomes contested. Please note, entity owners such as corporations or trusts, require an attorney to be on record, upon filing the complaint. The attorney will bill the client separately after starting eviction services.</p>
                                    <h3>What Happens Once Final Judgment Is Entered In My Favor?</h3>
                                    <p>When Judgment has been entered in your favor, it is time to schedule the Sheriff Lockout, if the tenants have not vacated the premises. You can also choose to do this step on your own or hire us to schedule the lockout.</p>
                                    <h4 style={{fontSize: "20px"}}>Sheriff Lockout Fees</h4>
                                    <p>$180 – Sheriff Lockout Fee</p>
                                    <p>$299 – Prepare and Courier Instructions to Sheriff</p>
                                    
                                    <h4 style={{fontSize: "20px"}}>Appoint LDA Pro Legal to meet the Sheriff and Change the Locks: $599 (Optional)</h4>
                                    <p>You have the option of meeting the sheriff and changing the locks yourself. You can also hire us to meet the sheriff at the property and change all the locks.</p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>



            <section>
                <div className="container mt-5 mb-5">
                    <div className="row ">
                        <div className="col-md-6">
                            <div className="faq-section">
                                <h2>
                                    Frequently Asked Questions	
                                </h2>
                                <div className="faq scroll scroll1">
                                    <div>
                                        <div className="question">
                                        1. How Do I Start the Eviction Process?
                                        </div>
                                        <div className="answer">
                                            <p>Please click the START NOW button, to navigate to the client intake form. LDA Pro Legal prepares, files, and serves all the necessary documents, from start to finish. LDA Pro Legal emails documents for e-signature and will inform you of next steps, up until final judgment has been entered in your favor. The entire process is pay-as-you-go, up until final judgment.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        2. What Notice Should I Serve?
                                        </div>
                                        <div className="answer">
                                            <p>The notice is the most critical part of the entire eviction process. It must be prepared and served correctly, or you risk your case being dismissed at the court hearing. We have explanations of each notice that is appropriate to use, in your situation, on our intake form. We prepare the notice with all the legal language that is required in California. We inform you of what to do and what not to do, so your notice does not become invalid. We have the notice served by a registered process server.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        3. If I Start the Process with LDA Pro Legal, When Can I Proceed with the Eviction?
                                        </div>
                                        <div className="answer">
                                            <p>Once the notice expires and the tenants have not complied with the notice, you may proceed with the eviction process. If you hired LDA PRO Legal to prepare and serve the notice, simply email us and request to proceed with the eviction process once the notice expires.</p>	
                                            <p>We will email an invoice to proceed with the Eviction / Unlawful Detainer. We prepare all eviction court documents within 3 business days and email them to you, for signature. Once signed, we proceed with filing and serving.</p>	
                                        </div>
                                    </div>
                                    <div>
                                        <div className="question">
                                        4. What If I Prepared and Served My Own Notice?
                                        </div>
                                        <div className="answer">
                                            <p>Please keep in mind that the notice is the most critical part of the entire complaint. It must be prepared and served correctly. If you did not hire LDA PRO Legal to prepare and serve the Notice, you may hire us to prepare the Eviction / Unlawful Detainer. You must complete the online intake form and choose the second option. We will attach the notice you served, to the complaint. We can not assert the validity of a notice that was not prepared and served by us.</p>	
                                        </div>
                                    </div>
                                    <div>
                                        <div className="question">
                                        5. How Long Does the Eviction Process Take?
                                        </div>
                                        <div className="answer">
                                            <p>Evictions range in processing time depending on the county in which the property is located. Typically you can expect about 2 to 3 months for an uncontested eviction and about 3 to 4 months for a contested eviction.</p>	
                                        </div>
                                    </div>
                                    <div>
                                        <div className="question">
                                        6. How are the Documents Prepared?
                                        </div>
                                        <div className="answer">
                                            <p>You can complete the entire eviction process, from the comfort of your own home. Once the intake form is completed we review the intake form and reach out with any further questions we may have. All documents are hand prepared by the Registered Unlawful Detainer Assistant. Once the documents are prepared, the documents are emailed to the client, for signature. Once we receive the signed documents via email, we proceed with filing and serving. We keep you in the loop of the progress in your case, via email, every step of the way.</p>	
                                        </div>
                                    </div>
                                    <div>
                                        <div className="question">
                                        7. Why should I hire a Registered Unlawful Detainer Assistant? 
                                        </div>
                                        <div className="answer">
                                            <p>Registered Unlawful Detainer Assistants (UDAs) are authorized to render assistance or advice in the prosecution of an unlawful detainer claim or action. Bus. & Prof. Code Section 6400 (a). UDAs are not attorneys and can not represent you in court. Should you require an attorney to represent you in court, one of our cooperating landlord eviction attorneys can represent you for a discounted fee. When you are deciding to choose an eviction service company to handle your eviction court case, be sure to check credentials, experience, and reviews. It is important to the success of your case to hire a Registered Unlawful Detainer Assistant or experienced landlord eviction Attorney. </p>	
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="col-md-6 pt-4">
                            <div className="faq-image">
                                <img src={require("../../assets/images/service/eviction/eviction_img02.png")} className="img-fluid" alt=""/>	
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
        </>
    );
};
