import React, {useEffect} from "react";
import {Link} from "react-router-dom"
import ContactSection from '../../components/common/ContactSection'
import CTA from '../../components/common/CTA'

export const Divorce = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <div className="container-fluid" id="inner-banner">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-xl-12">
                            <div className="about-content">
                                <div className="breadcrumbs">
                                    <div className="title">LDA PRO LEGAL</div>
                                    <h1>Divorce</h1>
                                    <div className="text" id="txt">Divorce Filing Document Preparation in Sacramento, California and Surrounding Counties.</div>
                                    <p className="mt-2">
                                        <React.Fragment>
                                            <Link to="/services/divorce/start-divorce-filing-online/" className="btn-style-three theme-btn" id="round-button">
                                            <span className="txt">START NOW</span></Link>
                                        </React.Fragment>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <div className="box-thumb">
                            <div className="image_section">
                                <img src={require("../../assets/images/service/divorce/close-up-woman-taking-wedding-ring-off_23-2148548576.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <div className="content">
                                <h2>PARALEGAL vs L.D.A.</h2>
                                <p>
                                    A Paralegal is someone who works for and is supervised by an attorney. By law a paralegal cannot be contracted by someone other than an attorney, this includes the general public. The “Illegal Paralegal” is someone who is preparing documents for the general public and is not a Registered and Bonded Legal Document Assistant. Paralegals do not have the same legal requirements as LDAs.
                                </p>
                                <Link id="round-button" className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/divorce/start-divorce-filing-online"><span className="txt">Start Now</span></Link>
                            </div>
                        </div>	
                    </div>

                    <div className="col-md-4 mt-3">
                        <div className="box-thumb">
                            <div className="image_section">
                                <img src={require("../../assets/images/service/divorce/high-angle-wedding-rings-agreement_23-2148542335.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <div className="content">
                                <h2>LEGAL DOCUMENT ASSISTANT, L.D.A.</h2>
                                <p>
                                    Legal Document Assistant, LDA is a paralegal who registers at the county recorder’s office, as an LDA, and files a bond. A Legal Document Assistant can be contracted by the general public and consumers to prepare legal documents at the client’s direction. An LDA has special training and certifications, such as graduating from an ABA Approved Legal Assisting Program. They are also required to maintain continuing education credits.
                                </p>
                                <Link id="round-button" className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/divorce/start-divorce-filing-online"><span className="txt">Start Now</span></Link>
                            </div>
                        </div>	
                    </div>	

                    <div className="col-md-4 mt-3">
                        <div className="box-thumb">
                            <div className="image_section">
                                <img src={require("../../assets/images/service/divorce/breakup-marriage-couple-with-divorce-certification_53876-14775.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <div className="content">
                                <h2>DIFFERENCES AND SIMILARITIES </h2>
                                <p>
                                    Legal Document Assistants are professionals that are legally able to prepare legal documents at the client’s direction. An LDA is able to prepare legal documents without the direct supervision of an attorney, unlike a Paralegal. However, neither an LDA or a Paralegal are attorneys and cannot represent you in court.
                                </p>
                                <Link id="round-button" className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/divorce/start-divorce-filing-online"><span className="txt">Start Now</span></Link>
                            </div>
                        </div>	
                    </div>		
                </div>
            </div>
            <section className="bg-one mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="content-part">
                                <h2>
                                    BREAKDOWN OF DIVORCE TERMS
                                    	
                                </h2>
                                <h2>
                                What Is An Uncontested Divorce?	
                                
                                </h2>
                                <p>
                                An Uncontested Divorce is a type of divorce in which the spouses agree on everything about the divorce, such as: division of assets, custody, alimony, etc. We help prepare the documents for your uncontested divorce and file them with your local court, or court of choosing.	
                                    
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="image-part">
                            <img src={require("../../assets/images/service/divorce/image45.jpg")} className="img-fluid" alt=""/>
                                	
                            </div>	
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6 mt-3">
                            <div className="image-part">
                            <img src={require("../../assets/images/service/divorce/close-up-hand-signing-paper_23-2148558450.jpg")} className="img-fluid" alt=""/>
                            </div>	
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="content-part">
                                <h2>
                                What Is A Contested Divorce?
                                </h2>
                                <p>
                                Contested Divorce is a type of divorce in which neither spouse can come to an agreement, whether it’s about the divorce itself (second thoughts), division of assets, child support/custody, or even spousal support/alimony. We help prepare the documents for your contested divorce and file them with your local court, or court of choosing.	
                                </p>
                            </div>
                        </div>

                    </div>	
                </div>
            </section>
            <CTA/>
            <br/>
            <br/>
            <ContactSection/>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-xl-12 text-center">
                        <div className="separator">
                            <div className="line"></div>
                            <h2>Payment Plans Available</h2>
                            <div className="line"></div>
                        </div><p>Price Includes Notarized Marriage Stipulation Agreement, If Applicable, For An Uncontested Divorce.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 mt-3">
                        <div className="price-sec-one">
                            <div className="price-image">
                                <img src={require("../../assets/images/service/divorce/image78.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <h2>
                                Divorce Document Preparation Fees:	
                            </h2>
                            <ul>
                                <li>Divorce or Legal Separation No Kids, No Assets- <span className="price1">$599</span></li>
                                <li>Divorce or Legal Separation With Children or Assets - <span className="price1">$798</span></li>
                                <li>Divorce or Legal Separation With Children and Assets - <span className="price1">$997</span></li>
                            </ul>	
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">

                        <div className="price-sec-one">
                            <div className="price-image">
                                <img src={require("../../assets/images/service/divorce/couple-with-divorce-contract-ring_220873-3216.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <h2>
                                Additional Fees:	
                            </h2>
                            <ul>
                                <li>Mandatory Court Filing Fee <span className="price1">$435</span></li>
                                <li>Optional Court Filing Service - <span className="price1">$99</span></li>
                                <li>Optional Process Serving - <span className="price1">$99</span></li>
                            </ul>	
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-one mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content-part">
                                <h2>
                                    Understanding the Divorce Process
                                </h2>
                                <p>
                                    Divorce can be a challenging and emotionally draining experience. Understanding the legal process is essential to navigate it successfully. Divorce is the formal termination of a marriage by a court, which legally frees both parties from marital obligations. It involves dividing assets, resolving child custody, and addressing spousal support. The divorce process can vary significantly depending on whether it is contested or uncontested. In an uncontested divorce, both parties agree on all terms, making the process quicker and less expensive. However, contested divorces can be more complex and time-consuming, requiring court intervention to resolve disputes. It’s important to know the state-specific regulations, as divorce laws differ across jurisdictions. For instance, California requires a six-month residency before filing. Understanding the divorce process helps you prepare better, reduce stress, and avoid common pitfalls. Seeking professional guidance early on, such as consulting a paralegal or a legal document assistant, can be beneficial in navigating this complex process smoothly.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-part">
                                <img src={require("../../assets/images/service/divorce/divorce_img11.png")} className="img-fluid" alt=""/>	
                            </div>	
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="image-part">
                                <img src={require("../../assets/images/service/divorce/woman-signing-divorce-contract_23-2148536100.jpg")} className="img-fluid" alt=""/>	
                            </div>	
                        </div>
                        <div className="col-md-6">
                            <div className="content-part">
                                <h2>
                                    Filing for Divorce: Step-by-Step Guide
                                </h2>
                                <p>
                                    Filing for divorce can be a complex process, but breaking it down into steps makes it more manageable. The first step is to complete and file the divorce petition, which outlines the grounds for the divorce and any initial requests regarding child custody, property division, or spousal support. Next, you must serve the divorce papers to your spouse. Proper service is crucial, as it informs your spouse of the proceedings and gives them a chance to respond. This can be done through personal delivery by a third party or a professional process server. After service, your spouse has a certain time frame to file a response, typically within 30 days. If they fail to respond, the court may grant a default judgment. If the divorce is contested, the next step involves exchanging financial disclosures, which help in dividing assets equitably. Finally, the court will schedule a hearing or mediation to resolve disputes and issue a final judgment. By following these steps carefully, you can ensure that your divorce process progresses smoothly.
                                </p>
                            </div>
                            <Link id="round-button" className="btn-style-three" to="/services/divorce/start-divorce-filing-online" style={{position:"inherit"}}><span className="txt">Start Now</span></Link>
                        </div>

                    </div>	
                </div>
            </section>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 mt-3">
                        <div className="price-sec-one">
                            <div className="price-image">
                                <img src={require("../../assets/images/service/divorce/image56.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <h2>Contested vs. Uncontested Divorce: What’s the Difference?</h2>
                            <p>The type of divorce you pursue—contested or uncontested—can greatly impact the duration, cost, and complexity of your case. An uncontested divorce is when both parties agree on all aspects, such as asset division, child custody, and support arrangements. This type of divorce is generally quicker and less costly since it does not require multiple court hearings. In many cases, couples can settle with the assistance of a mediator or legal document assistant, allowing for a smoother transition. On the other hand, a contested divorce occurs when the spouses cannot reach an agreement on one or more issues. This situation requires court intervention, where a judge will make the final decisions after hearing both sides. Contested divorces are typically more time-consuming and expensive due to the need for legal representation, court appearances, and sometimes even trial proceedings. Understanding the differences between contested and uncontested divorces helps you evaluate your situation and choose the path that best suits your needs.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">

                        <div className="price-sec-one">
                            <div className="price-image">
                                <img src={require("../../assets/images/service/divorce/image78-1.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <h2>Legal Requirements for Filing a Divorce</h2>
                            <p>Filing for divorce comes with certain legal requirements that must be met for the process to proceed. One of the primary requirements is the residency stipulation. For instance, in California, at least one spouse must have lived in the state for six months and in the county where the divorce is filed for at least three months. Additionally, the petitioner must choose a valid ground for divorce, such as irreconcilable differences or incurable insanity, depending on the state's regulations. Most states now allow for no-fault divorces, which means that neither party needs to prove wrongdoing by the other. Another crucial requirement is the completion of financial disclosures. Both parties must provide an honest account of their income, debts, assets, and expenses. This transparency ensures fair asset division and spousal support determinations. Failing to meet these requirements can delay the process, making it essential to fully understand the legal criteria before proceeding with your divorce filing.</p>	
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <br/>
            <br/>
            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="para-12">
                                <h2>The Role of a Legal Document Assistant in Divorce Filing</h2>
                                <p>Navigating the divorce process without legal representation can be daunting, but a Legal Document Assistant (LDA) can provide invaluable support. An LDA is a non-attorney professional authorized to prepare legal documents at the direction of their clients. They can assist with completing and filing divorce paperwork, which can save time and reduce stress for those seeking a more affordable alternative to hiring a divorce attorney. LDAs are particularly helpful in uncontested divorces, where the need for legal advice is minimal, and the focus is on correctly filling out and submitting forms. By guiding clients through the documentation requirements, an LDA ensures that all necessary paperwork is complete and in compliance with state-specific regulations. They can also help with financial disclosures, child custody agreements, and spousal support calculations. While LDAs cannot offer legal advice or represent clients in court, their services provide a cost-effective option for individuals looking to manage their divorce filing with professional assistance.</p>
                            </div>

                            <div className="para-12">
                                <h2>The Importance of Financial Disclosures in Divorce</h2>
                                <p>Financial disclosures play a crucial role in the divorce process, as they provide transparency and ensure an equitable division of assets. Both parties are required to complete a financial disclosure statement that lists all sources of income, debts, assets, and liabilities. This information is used to determine fair property division, child support, and spousal support. Accurate financial disclosures help prevent disputes and ensure that both parties receive a fair share of the marital property. Failing to disclose assets can result in legal consequences, such as a court order to pay fines or even a reallocation of hidden assets. Additionally, accurate disclosures are essential for calculating child support and alimony payments, ensuring that the needs of any children are met and that both parties can maintain a reasonable standard of living post-divorce. Consulting with a paralegal or LDA can be beneficial in ensuring that your financial disclosures are thorough and comply with legal standards, avoiding delays and complications in the process.
                                </p>
                            </div>
                        </div>	
                    </div>	
                </div>
            </section>
            <section>
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="faq-section">
                                <h2>
                                    Frequently Asked Questions	
                                </h2>
                                <div className="faq scroll scroll1">
                                    <div>
                                        <div className="question">
                                            1. What are the legal requirements for filing a divorce in California?
                                        </div>
                                        <div className="answer">
                                            <p>To file for divorce in California, at least one spouse must have lived in the state for six months and in the county where the divorce is being filed for at least three months. Additionally, you need to provide a reason for the divorce, such as irreconcilable differences. California allows no-fault divorces, meaning that neither spouse needs to prove wrongdoing by the other. It's important to ensure that you meet these residency requirements before starting the filing process to avoid any delays.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                            2. What is the difference between a contested and an uncontested divorce?
                                        </div>
                                        <div className="answer">
                                            <p>An uncontested divorce occurs when both spouses agree on all aspects of the divorce, including asset division, child custody, and support arrangements. This type of divorce is generally quicker, less costly, and does not require multiple court appearances. A contested divorce, on the other hand, occurs when spouses cannot reach an agreement on one or more issues, requiring court intervention. Contested divorces are typically more time-consuming and expensive due to legal fees, court hearings, and possible trials.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                            3. How long does it take to finalize a divorce in California?
                                        </div>
                                        <div className="answer">
                                            <p>The time required to finalize a divorce in California depends on various factors, including whether the divorce is contested or uncontested. California has a mandatory six-month waiting period, which begins once the divorce petition is served to the other spouse. For uncontested divorces, the process can be completed soon after the waiting period ends if all paperwork is in order. Contested divorces can take longer, often a year or more, depending on the complexity of the case and the court’s schedule.</p>	
                                        </div>
                                        <div>
                                            <div className="question">
                                                4. Do I need a lawyer to file for divorce, or can I do it myself?
                                            </div>
                                            <div className="answer">
                                                <p>You are not legally required to hire a lawyer to file for divorce in California. Many individuals choose to handle the process themselves, especially in uncontested divorces where both parties agree on all terms. However, managing the paperwork and ensuring compliance with legal requirements can be challenging. In such cases, working with a Legal Document Assistant (LDA) or a paralegal can be a cost-effective alternative, providing assistance with the preparation and filing of necessary forms without the higher fees associated with attorneys.</p>	
                                            </div>
                                            <div>
                                                <div className="question">
                                                    5. How are child custody and support determined in a divorce?
                                                </div>
                                                <div className="answer">
                                                    <p>Child custody and support are determined based on the best interests of the child. The court considers factors such as the child's age, emotional and physical needs, the relationship between the child and each parent, and the ability of each parent to provide a stable home environment. Child support is calculated using state guidelines that take into account both parents' incomes and the time each parent spends with the child. While parents can come to their own agreements on custody and support, these agreements must still be approved by the court to ensure they meet the child's needs.</p>	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-4">
                            <div className="faq-image">
                                <img src={require("../../assets/images/service/divorce/image78.jpg")} className="img-fluid" alt=""/>	
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CTA/>
            <br/>
            <br/>
            <div className="container  mb-5">
                <div className="row">
                    <div className="col-md-6 mt-3">
                        <div className="price-sec-one">
                            <div className="price-image">
                                <img src={require("../../assets/images/service/divorce/black-woman-pointing-document-near-lady-with-pen-table-with-calculator-gavel_23-2148042590.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <h2>Child Custody and Support Considerations During Divorce</h2>
                            <p>Child custody and support are among the most sensitive issues in a divorce, often requiring careful negotiation and legal guidance. Custody arrangements can be classified into physical custody, which determines where the child lives, and legal custody, which involves decision-making authority regarding the child's upbringing. Courts prioritize the best interests of the child when making custody decisions, considering factors like the child's age, emotional needs, and each parent's ability to provide a stable environment. Child support, on the other hand, is calculated based on the financial needs of the child and the income of both parents. It aims to ensure that the child maintains a similar standard of living to what they experienced during the marriage. Even in uncontested divorces, having a clear and legally binding custody and support agreement is crucial for both parties' peace of mind. A Legal Document Assistant can help draft these agreements, ensuring that they meet state guidelines and are fair to both parties.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">

                        <div className="price-sec-one">
                            <div className="price-image">
                                <img src={require("../../assets/images/service/divorce/image789.jpg")} className="img-fluid" alt=""/>	
                            </div>
                            <h2>Finalizing Your Divorce: What to Expect</h2>
                            <p>Finalizing a divorce marks the end of the legal process, but it’s important to know what to expect as you approach this stage. Once all disputes have been resolved, either through mediation, negotiation, or court hearings, a final judgment is issued by the court. This judgment outlines the terms of the divorce, including child custody arrangements, division of assets, spousal support, and any other relevant orders. Before the judgment can be finalized, a mandatory waiting period must be completed—six months in California, for example. During this time, both parties should review the terms carefully to ensure accuracy and fairness. After the waiting period, the court will issue the final decree, officially terminating the marriage. Once this decree is issued, both parties must comply with its terms, including transferring assets or arranging child visitation. Working with a Legal Document Assistant throughout this process can help ensure that all paperwork is in order, making the finalization of your divorce as smooth as possible.</p>	
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}