import React, {useEffect,useState} from "react";
import {useLocation } from 'react-router-dom';
import axios from 'axios'; // Axios for HTTP requests


interface HtmlDecoderProps {
    html: string; // Define the type of the html prop
}
const HtmlDecoder: React.FC<HtmlDecoderProps> = ({ html }) => {
    const decodeHtmlEntities = (html: string): string => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };

    const decodedHtml = decodeHtmlEntities(html);
    
    return (
        <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />
    );
};
type Area = {
    slug: string;
    name: string;
    featured_image: string;
    content: string;
};
export const SingleBlog = (): React.ReactElement<any, any> => {
    const [area, setArea] = useState<Area | null>(null);
    const location = useLocation(); // Get the location object
    const pathSegments = location.pathname.split('/'); // Split the pathname into segments
    var lastSegment = pathSegments[pathSegments.length - 1];
    if(lastSegment === "")
        lastSegment = pathSegments[pathSegments.length - 2];
    const singleurl = 'https://server.ldaprolegal.com/api/v2/get-single-blog/'+lastSegment;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(singleurl);
                setArea(response.data); // Set posts with fetched data
                console.log(response.data);
            } catch (error) {
                console.error(error); // Log error if request fails
            }
        };

        fetchData(); // Call fetch function
    }, [lastSegment]); // Empty dependency array to run only on mount

    return (
        <>
            {area ? (
                <div className="container">
                    <img src={area.featured_image} className="img-fuild post-image" alt={`${area.name}`}></img>
                    <HtmlDecoder html={area.content} /> 
                </div>
            ) : (
                <p className="text-center">Loading...</p> // Display loading message or spinner
            )}
        </>
    );
};
