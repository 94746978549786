import React, {useEffect} from "react";
import { Link } from "react-router-dom"

export const Areas = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });

    return (
        <>
            <div className="container mt-5" id="services_section">
                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <div className="separator">
                                <div className="line"></div>
                                <h2>Practice Areas</h2>
                                <div className="line"></div>
                            </div>
                            <p>Our skilled and competent LDAs stretch their helping hands to resolve all your legal issues throughout Sacramento:</p>
                        </div>
                    </div>
                
                    <div className="row">
                        <div className="col-md-4 mb-5">
                            <ul className="ul-style-2 wow fadeInRight animated" data-wow-delay=".5s">
                                <li><Link to="/areas/sacramento">Sacramento</Link></li>
                                <li><Link to="/areas/placer">Placer</Link></li>
                                <li><Link to="/areas/yolo">Yolo</Link></li>
                                <li><Link to="/areas/yuba">Yuba</Link></li>
                                <li><Link to="/areas/el-dorado">El Dorado</Link></li>
                            </ul>
                        </div>
                     <div className="col-md-4 mb-5">
                        <ul className="ul-style-2 wow fadeInRight animated" data-wow-delay="1s">
                            <li><Link to="/areas/solano">Solano</Link></li>
                            <li><Link to="/areas/san-joaquin">San Joaquin</Link></li>
                            <li><Link to="/areas/shasta">Shasta</Link></li>
                            <li><Link to="/areas/sutter">Sutter</Link></li>
                            <li><Link to="/areas/alameda">Alameda</Link></li>
                            <li><Link to="/areas/contra-costa">Contra Costa</Link></li>
                        </ul>
                    </div>	
                    
                    <div className="col-md-4 mb-5">
                        <ul className="ul-style-2 wow fadeInRight animated" data-wow-delay="1.5s">
                            <li><Link to="/areas/marin">Marin</Link></li>
                            <li><Link to="/areas/napa">Napa</Link></li>
                            <li><Link to="/areas/san-francisco">San Francisco</Link></li>
                            <li><Link to="/areas/san-mateo">San Mateo</Link></li>
                            <li><Link to="/areas/santa-clara">Santa Clara</Link></li>
                            <li><Link to="/areas/contra-costa">Contra Costa</Link></li>
                        </ul>
                    </div>	
                </div>
            </div>
            <div className="container eviction-bottom-section">
                <div className="row">
                    <div className="col-sm-12">
                        <h2><strong>Legal Document Assistants in California: Simplifying Your Legal Processes</strong></h2>
                        <p>Looking for Legal Document Assistants in California? Look no further! Our team at LDA PRO LEGAL, Legal Document Assistants in California is here to simplify your legal processes and provide you with the support you need. With our expertise and attention to detail, we strive to ensure that your legal documents are accurately prepared, filed, and processed, saving you time, effort, and potential complications.</p>
                        <h3><strong>Comprehensive Legal Document Services</strong></h3>
                        <p>At LDA PRO LEGAL, we offer a wide range of services to meet your specific needs. Whether you require assistance with family law documents, estate planning, business contracts, real estate agreements, eviction, probate, or any other legal documentation, our team is equipped to handle it all. We understand that navigating legal processes can be overwhelming, which is why our dedicated professionals are here to guide you every step of the way.</p>
                        <h3><strong>Experienced Professionals at Your Service</strong></h3>
                        <p>Our team consists of experienced legal document assistants who possess in-depth knowledge and expertise in various legal areas. We have a thorough understanding of the legal requirements in California and stay up-to-date with any changes in legislation or procedures. When you choose Legal Document Assistants in California, you can trust that your legal documents will be prepared accurately and in compliance with the relevant regulations.</p>
                        <h3><strong>Personalized Attention and Tailored Solutions</strong></h3>
                        <p>We believe in providing personalized attention to each client and tailoring our services to meet their specific requirements. We take the time to listen to your needs, answer your questions, and provide guidance throughout the process. Our goal is to ensure that your legal documents accurately reflect your intentions and protect your rights and interests.</p>
                        <h3><strong>Save Time and Avoid Mistakes</strong></h3>
                        <p>By entrusting your legal document preparation to our team, you can save valuable time and avoid costly mistakes. We understand the intricacies involved in legal paperwork, and our meticulous approach ensures that all necessary information is included and that forms are completed correctly. Our expertise helps streamline the process, allowing you to focus on other important aspects of your life or business.</p>
                        <h3><strong>Affordable and Transparent Pricing</strong></h3>
                        <p>We believe that quality legal document assistance should be accessible to everyone. Our services are competitively priced, and we provide transparent pricing information upfront. You can rest assured that there will be no hidden fees or surprises along the way. We strive to deliver exceptional value for your investment, providing you with peace of mind knowing that your legal documents are handled professionally and efficiently.</p>
                        <h3><strong>Contact LDAPRO, Legal Document Assistants in California Today</strong></h3>
                        <p>Don’t let the complexities of legal documentation overwhelm you. With LDAPRO, Legal Document Assistants in California, you have a trusted partner to simplify the process and provide you with the professional support you need. Our team is dedicated to ensuring that your legal documents are accurate, complete, and filed in a timely manner.</p>
                        <p><Link to="/contact-us">Contact Legal Document Assistants in California today</Link> to discuss your specific requirements and let us assist you with your legal document needs. We are here to make your legal processes smoother and more efficient, allowing you to move forward with confidence. Trust us to handle your legal documents with care, precision, and the utmost professionalism. <a href="https://goo.gl/maps/6D2igryyqT57jVDXA" target="_blank" rel="noreferrer">Visit Us Today!</a></p>
                    </div>
                </div>
            </div>
        </>
    );
};
