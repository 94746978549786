import React, {useEffect} from "react";
import classNames from "classnames";
import Accordion from '../components/accordion/Accordion';
import AccordionItem from '../components/accordion/AccordionItem';





export const FAQ = (): React.ReactElement => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <div className="container">
                <div className="entry-content">
                <section className={classNames("features-tabs section center-content")} style={{ paddingTop: 0 }}>
                    <div className="container">
                        <div className={classNames("features-tabs-inner section-inner")} style={{paddingTop:'0'}}>
                                <Accordion className={''}>
                                    <AccordionItem title="What areas of law does LDA PRO Legal specialize in?" className="" isActive="" onToggle="">
                                    LDAPRO Legal specializes in a wide range of legal areas, including but not limited to family law, personal injury, immigration law, real estate law, business law, and estate planning.
                                    </AccordionItem>
                                    <AccordionItem title="How experienced are the lawyers at LDA PRO Legal?" className="" isActive="" onToggle="">
                                    The lawyers at LDAPRO Legal have extensive experience in their respective fields. Our legal team comprises highly skilled and knowledgeable professionals who have handled numerous cases and achieved successful outcomes for our clients.
                                    </AccordionItem>
                                    <AccordionItem title="Can LDA PRO Legal assist with both personal and business legal matters?" className="" isActive="" onToggle="">
                                    Yes, LDAPRO Legal is equipped to handle both personal and business legal matters. Our lawyers are experienced in providing legal services to individuals, families, entrepreneurs, and businesses of all sizes.
                                    </AccordionItem>
                                    <AccordionItem title="How can I schedule a consultation with LDA PRO Legal?" className="" isActive="" onToggle="">
                                    Scheduling a consultation with LDAPRO Legal is easy. You can reach out to us through our contact page on the website or call our office directly. Our friendly staff will assist you in setting up a convenient time for a consultation with one of our lawyers.
                                    </AccordionItem>
                                    <AccordionItem title="What are the typical fees or billing structures for legal services provided by LDA PRO Legal?" className="" isActive="" onToggle="">
                                    The fees and billing structure at LDAPRO Legal may vary depending on the nature of the legal matter. We offer transparent and competitive pricing, and our billing arrangements can be discussed during the initial consultation. We strive to provide cost-effective solutions while delivering high-quality legal representation.
                                    </AccordionItem>
                                    <AccordionItem title="Does LDA PRO Legal offer any free initial consultations?" className="" isActive="" onToggle="">
                                    Yes, LDAPRO Legal offers free initial consultations for certain types of cases. During this consultation, you can discuss your legal issue, receive preliminary advice, and assess whether our firm is the right fit for your needs.
                                    </AccordionItem>
                                    <AccordionItem title="Can LDA PRO Legal handle cases or clients outside of their local jurisdiction?" className="" isActive="" onToggle="">
                                    LDAPRO Legal has experience handling cases across different jurisdictions. While our primary location is California and its surrounding counties, we can assist clients with legal matters in other jurisdictions as well. We can provide guidance and representation based on the specific laws and regulations applicable to your case.
                                    </AccordionItem>
                                    <AccordionItem title="Are there any specific qualifications or certifications that the lawyers at LDA PRO Legal possess?" className="" isActive="" onToggle="">
                                    The lawyers at LDAPRO Legal possess a range of qualifications and certifications. Many of our attorneys are licensed to practice law in multiple jurisdictions and have obtained specialized certifications or advanced degrees in their respective areas of expertise.
                                    </AccordionItem>
                                    <AccordionItem title="What is the average timeframe for resolving a legal matter with LDA PRO Legal?" className="" isActive="" onToggle="">
                                    The timeframe for resolving a legal matter varies depending on the complexity and unique circumstances of each case. LDAPRO Legal strives to efficiently handle cases while maintaining a high level of quality and attention to detail. During the initial consultation, our lawyers can provide you with a more accurate estimate of the expected timeline for your specific legal matter.
                                    </AccordionItem>
                                    <AccordionItem title="Can LDA PRO Legal provide references or testimonials from previous clients?" className="" isActive="" onToggle="">
                                    Absolutely! LDAPRO Legal can provide references or testimonials from previous clients upon request. We take pride in our track record of client satisfaction, and we are happy to share the experiences of those we have served.
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};
