import React, {useEffect} from "react";
import {Link} from 'react-router-dom'

export const Testimonials = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <section className="review-sec">
                <div className="container">
                    <div className="sec-header">
                        <h2> What Our Clients Say on Google</h2>
                        <p>
                            Read real client experiences and testimonials about our services, showcasing trust, reliability, and
                            excellence in every interaction.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/google.png')} alt="google Logo"/>
                                    </div>
                                </div>
                                <p className="review">In California, I've been told, you can't evict someone without legal help. I'm glad LDA is here with that help without which I'd probably still be going through this quagmire. Angela made the whole process streamlined and made the whole process simple for me. If only California hasn't made the whole process such a nightmare that it took 7 months for a straight-forward none-contested case of late/none payment of rent and other blatant violations. I'm still in a state of disbelief.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">T</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Tae Kim</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/google.png')} alt="google Logo"/>
                                    </div>
                                </div>
                                <p className="review">LDA PRO helped us evict our non-paying tenants, it was a VERY stressful time, but Angela made sure all of our forms were filled out properly.  It was cost effective compared to going straight to a lawyer. Do not hesitate to use them, they will help navigate the confusing, lengthy process! Prayers to fellow landlords going through this!</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">L</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Laura Wyldflower</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/google.png')} alt="google Logo"/>
                                    </div>
                                </div>
                                <p className="review">Highly recommend this business. Angela was a pleasure to work with. She traveled quite a ways to our meeting place and was on time and very professional. Even though our appointment time was late on a Friday she was able to record the notarized document the same day!!! Very impressed. I would hire her again in a heartbeat. Thank you Angela!</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">K</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Kay J</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/google.png')} alt="google Logo"/>
                                    </div>
                                </div>
                                <p className="review">Angela is very responsive, reliable and very knowledgeable person in her field. she respond to my questions at anytime of day or night to help me push the process though as fast as possible. I strongly recommend her for your legal help.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">K</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">kenny wong</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/google.png')} alt="google Logo"/>
                                    </div>
                                </div>
                                <p className="review">Amazing, I was so reluctant to use this service but so grateful I did. They were so easy to work with. They were able to find the defendant and serve them in a week. Something I have not been able to do in a year. Thank you Angela for taking the stress off of me and doing what I paid you too do. I would refer them to anyone needed assistance. They are great!</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">T</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Traneil Missouri</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/google.png')} alt="google Logo"/>
                                    </div>
                                </div>
                                <p className="review">I had a great experience working with LDA PRO. Angela is very professional, knowledgeable and helpful. I was able to evict my tenant quickly because she was so responsive and efficient.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">C</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Colleen Olmstead</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-5">
                            <a href="https://www.google.com/maps/place/LDA+Pro+Legal+-+Legal+Document+Assistants/@38.6301907,-121.3819912,1111m/data=!3m2!1e3!5s0x809ad95be589fc25:0x4b21663b6682ded4!4m8!3m7!1s0x809ad794cb08988b:0xf26331dc35857cf!8m2!3d38.6301907!4d-121.3819912!9m1!1b1!16s%2Fg%2F11c0vp2k8g?entry=ttu&g_ep=EgoyMDI1MDMzMS4wIKXMDSoASAFQAw%3D%3D" className="reviewMoreButton" target="_Blank" rel="noreferer"><img src={require('../assets/images/google.png')} alt="google Logo"/> View More on Google</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="review-sec">
                <div className="container">
                    <div className="sec-header">
                        <h2>
                            Honest Feedback from Yelp Users
                        </h2>
                        <p>Explore honest feedback from satisfied customers who have shared their experiences, helping others make informed decisions confidently.</p>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/yelp.png')} alt="yelp Logo"/>
                                    </div>
                                </div>
                                <p className="review">I used LDA Pro Legal for evicting one of my tenants. They've prepared all necessary forms required from the 3-day notice all the way to the sheriff lockout. The entire process was smooth and headache free. Angela was always able to respond to all my inquiries and clarify the process and next steps. The process was complicated and can easily be derailed, but LDA Pro Legal services helped to ensure that the process is successful. As of yesterday, my tenant got evicted and locked out by the Sheriff. I'm very grateful happy customer! I highly recommend LDA Pro Legal!</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">I</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Ivy A</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/yelp.png')} alt="yelp Logo"/>
                                    </div>
                                </div>
                                <p className="review">Was in a difficult situation to have to evict a family member. Called several attorneys and no one would take my case.<br/>Found LDA Pro Legal. They took my case and kept me updated throughout the entire process.<br/>Made a stressful situation easier. Would HIGHLY recommend there services.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">M</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">mariah k.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/yelp.png')} alt="yelp Logo"/>
                                    </div>
                                </div>
                                <p className="review">If you need to evict a tenant, look no further. Angela Jones helped me from start to finish. She was always professional, responded to emails and messages quickly, even answered some of my questions after hours, she has been nothing but amazing. She is intelligent and prompt and follows through in the time frame that she lists. She knows how to file paperwork correctly and knows the California laws very well. Extremely recommend this company for any eviction service needed.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">K</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Krista B.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/yelp.png')} alt="yelp Logo"/>
                                    </div>
                                </div>
                                <p className="review">LDA Pro helped me after I terminated my attorney for screwing up my case. Instead, LDA executed notices, at a reasonable cost, got me a court date, at a reasonable cost, and connected me with another attorney, who handled my case in court. Angela is easy to work with, professional and an excellent value. I will be starting all legal actions with LDA for the foreseeable future. As a landlord, I consider this service to be important, as attorneys can be very expensive and wasteful. LDA is neither of these.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">M</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Michael M.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/yelp.png')} alt="yelp Logo"/>
                                    </div>
                                </div>
                                <p className="review">I needed to get a divorce quickly and without lawyers. Angela and Michelle are awesome! I have found them very knowledgeable, quick to return calls and emails and, now I am divorced in record time! They made it easy to take care of all the appropriate paperwork I needed to file with the courts and guided me through the whole process. It was a nice experience working with this office and I highly recommend them for your legal needs!</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">D</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Dennis M.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_red.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/yelp.png')} alt="yelp Logo"/>
                                    </div>
                                </div>
                                <p className="review">I started probate in November of 2021. I utilized services from another legal document assistant who was referred to us by a friend. After a series of unfortunate events, this person did not fulfill her contractual obligations. Thankfully, I found LDA Pro. Angela was quick to respond and help. She walked me through their process and reassured me they could handle my case. My case was assigned to James who did a phenomenal job! The documents he drafted were precise and clear. He answered my emails and calls in a timely fashion. The work he did allowed me to finish and close probate after just 3 1/2 months with LDA Pro. I am so grateful. Thank you, James and Angela!</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">S</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Sonia G.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-5">
                            <a href="https://www.yelp.com/biz/lda-pro-legal-sacramento-8" className="reviewMoreButton" target="_Blank" rel="noreferer"><img src={require('../assets/images/yelp.png')} alt="Yelp Logo"/> View More on Yelp</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="review-sec trust">
                <div className="container">
                    <div className="sec-header">
                        <h2>
                            Verified Ratings & Insights from Trustpilot
                        </h2>
                        <p>
                            Verified ratings and insights from real customers, reflecting trust, credibility, and overall satisfaction with our professional services.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/trustpilot.png')} alt="trustpilot Logo"/>
                                    </div>
                                </div>
                                <p className="review">I highly recommend them! They have helped me tremendously in the eviction process at a fraction of the price to hire a lawyer. Their expertise, professionalism, knowledge and reliability in this area is superb.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">TA</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Tanya Devoll</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/trustpilot.png')} alt="trustpilot Logo"/>
                                    </div>
                                </div>
                                <p className="review">All information is so clear to understand. It is well explained to process. Angela is very nice to answer my questions when I have first experience of eviction.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">TH</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Terry Hwang</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/trustpilot.png')} alt="trustpilot Logo"/>
                                    </div>
                                </div>
                                <p className="review">I’m a landlord I had an awesome attorney that charge an incredible price but he no longer practice law. Other attorneys either charge a arm and a leg or take forever to get things done. Angela the owner of LDA is very helpful and a charge a reasonable price. I will continue to give her my business when ever I need to evict a problem tenant or any other court documents and filing.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">DO</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Don</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/trustpilot.png')} alt="trustpilot Logo"/>
                                    </div>
                                </div>
                                <p className="review">After months of attempting to remove a squatter from my mother’s property in Alta, CA, I contacted Legal Documents Assistants (LDA PRO) to take over. Once I spoke to Ms Angela Jones about the extenuating circumstances of this case, I felt confident she could handle this matter. I have slept better since I am not thinking about what I may have done incorrectly on the court documents as they kept being rejected. Phew! Best decision we have made to get this person out of the cabin once and for all!!!</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">KA</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Karen A</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/trustpilot.png')} alt="trustpilot Logo"/>
                                    </div>
                                </div>
                                <p className="review">Prompt responses, competent advice and solid attention to detail. I successfully completed an eviction in Stockton CA with LDA PRO. Every step was well explained while forward looking expectations were reasonably predicted. The process took about 4 months, so it was important to have solid communication all the way through. The remote service worked equally well and cost was as reasonable as can be expected. I plan to keep using LDA PROs should the need arise.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">AP</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Avkash Patel</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                            <div className="review-outer">
                                <div className="star-logo">
                                    <ul>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                        <li><img src={require('../assets/images/star_green.png')} alt="star"/></li>
                                    </ul>
                                    <div>
                                        <img src={require('../assets/images/trustpilot.png')} alt="trustpilot Logo"/>
                                    </div>
                                </div>
                                <p className="review">Angela makes the stressful process simple. LDA Pro will be my go to for future issues related to tenant eviction or overall document related legalities. I love the forward approach, it really helped me understand the process.</p>
                                <div className="person">
                                    <div className="image">
                                        <div className="person_logo">LT</div>
                                    </div>
                                    <div className="person-details">
                                        <p className="name">Lynn Tran</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center mt-5">
                            <a href="https://www.trustpilot.com/review/ldaprolegal.com" className="reviewMoreButton" target="_Blank" rel="noreferer"><img src={require('../assets/images/trustpilot.png')} alt="Trustpilot Logo"/> View More on Trustpilot</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
