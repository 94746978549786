import React, {useEffect,useState} from "react";
import {Link} from 'react-router-dom'
import Swal from "sweetalert2";

export const ContactUs = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    const [formData, setFormData] = useState({
        Name: "",
        Phone: "",
        Email: "",
        Message: ""
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        try {
            const response = await fetch("https://server.ldaprolegal.com/api/v2/form-submit.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (result.success) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Your form has been submitted successfully.",
                });
            } else {
                alert("Failed to send message. Please try again.");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred.");
        }
    };
    
    return (
        <>
            <div className="container-fluid" id="contact-sections" style={{backgroundImage:"url(https://ldaprolegal.com/wp-content/uploads/2023/06/bg9.jpg)",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                <div className=" container contact-information-section">
                    <div className="row">
                        <div className="col-xl-6 col-sm-6">
                            <div className="contact-left-section">
                            <img src={require("../assets/images/about/angela-lda-pro.webp")} className="img-fluid" alt=""/>
                            </div>
                        </div>
                        <div className="col-12 mt-5 mb-5 d-sm-none">
                            <div className="contact-logo-outer">
                                <img src={require("../assets/images/common/lda-pro-new-logo.webp")} className="img-fluid" alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                            <div className="contact-right-section">
                                <div className="contact-location">
                                    <h2>
                                        Get In Touch	
                                    </h2>
                                    <div className="red-line">
                                    </div>
                                    &nbsp;&nbsp;
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                                            </svg>
                                            Location: 3550 Watt Ave., Suite 140, Sacramento, CA 95821
                                        </li>
                                        <li>
                                            <Link to="tel:(916) 620-2446">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone-fill" viewBox="0 0 16 16">
                                                    <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"></path>
                                                </svg>
                                                Main Office: (916) 620-2446
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="tel:1-844-453-2776">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone-fill" viewBox="0 0 16 16">
                                                    <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"></path>
                                                </svg>
                                                Toll Free: 1-844-453-2776
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="mailto:contact@ldaprolegal.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
                                                </svg>
                                                contact@ldaprolegal.com
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="legal-service-section" style={{backgroundImage:"url(https://ldaprolegal.com/wp-content/uploads/2024/07/bg12.jpg)",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h2 className="color-white">Questions?</h2>
                            <div className="contact-form">
                                <div className="wpcf7 js" id="wpcf7-f8620-o1" lang="en-US" dir="ltr">
                                    <div className="screen-reader-response">
                                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                                        <ul></ul>
                                    </div>
                                    <form  onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-6 ">
                                                <div className="form-group">
                                                    <input className="form-control" placeholder="Name *" type="text" name="Name" value={formData.Name} onChange={handleChange} required/>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 ">
                                                <div className="form-group">
                                                    <input className="form-control" placeholder="Phone *" type="tel" name="Phone" value={formData.Phone} onChange={handleChange} required/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <input className="form-control" placeholder="Email *" type="email" name="Email" value={formData.Email} onChange={handleChange} required/>
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <textarea cols={40} rows={10} className="form-control" placeholder="Message" value={formData.Message} onChange={handleChange} name="Message"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <p><button type="submit" className="btn btn-primary">Submit</button>
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
