import React, {useEffect} from "react";
import {Link} from "react-router-dom"
import ContactSection from '../../components/common/ContactSection'
import CTA from '../../components/common/CTA'

export const LivingTrust = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <div className="container-fluid" id="inner-banner">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-xl-12">
                            <div className="about-content">
                                <div className="breadcrumbs">
                                    <div className="title">LDA PRO LEGAL</div>
                                    <h1>Affordable Living<span className="theme-color"> <br/>Trust Package</span></h1>
                                    <div className="text" id="txt">Prepared by Registered LDAs, providing service throughout California.</div>
                                    <p className="mt-2">
                                        <React.Fragment>
                                            <Link to="/services/living-trust/start-living-trust-online/" className="btn-style-three theme-btn" id="round-button">
                                            <span className="txt">START NOW</span></Link>
                                        </React.Fragment>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container bg-white mb-5" id="seven-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-6 mb-4">
                        <div className="price-section-left">
                            <h2>INDIVIDUAL REVOCABLE LIVING TRUST</h2>
                            <div className="price">$799</div>
                            <p>Revocable Trust, Trust Declaration, Trust Certification, Will, Healthcare Directive, Power Of Attorney, and Trust Transfer Deed.</p>
                            <div id="button-box" className="btns-box mt-4 ml-3"><Link id="round-button" className="btn-style-three theme-btn" to="/services/living-trust/start-living-trust-online/"><span className="txt">START NOW</span></Link></div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 mb-4">
                        <div className="price-section-left">
                            <h2>COUPLES REVOCABLE LIVING TRUST</h2>
                            <div className="price">$899</div>
                            <p>Revocable Trust, Trust Declaration, Trust Certification, Will, Healthcare Directive, Power Of Attorney, and Trust Transfer Deed.</p>
                            <div id="button-box" className="btns-box mt-4 ml-3"><Link id="round-button" className="btn-style-three theme-btn" to="/services/living-trust/start-living-trust-online/"><span className="txt">START NOW</span></Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <div className="container bg-white" id="living-section">
                <div className="row">
                    <div className="col-xl-4 mt-4">
                        <div className="living-thumbnail">
                            <img src={require("../../assets/images/service/trust/business-team-discussion-meeting-office_1262-1405.jpg")} className="img-fluid" alt=""/>
                            <h2 className="mt-3">EXPERIENCE &amp; PROFESSIONALISM	</h2>
                            <p>For over a decade, LDA PRO helped many clients prepare their Revocable Living Trust. It is important when searching for a legal professional to prepare your Living Trust, that they are knowledgeable in Estate Planning and use the appropriate up to date legal professional software and forms, when preparing your Living Trust. It is also important that your trust is funded appropriately.	</p>
                        </div>
                    </div>
                    <div className="col-xl-4 mt-4">
                        <div className="living-thumbnail">
                            <img src={require("../../assets/images/service/trust/agent-customer-making-deal_23-2147764248.jpg")} className="img-fluid" alt=""/>
                            <h2 className="mt-3">IMPORTANCE OF ESTATE PLANNING	</h2>
                            <p>Avoid probate and protect your assets, if you own real property or investments. Establishing a well thought out and properly funded living trust, provides asset protection for your surviving spouse and children, while maintaining better control over beneficiaries. It is most important to hire a Registered Legal Document Assistant that is knowledgeable, instead of doing it yourself and missing an important step.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 mt-4">
                        <div className="living-thumbnail">
                            <img src={require("../../assets/images/service/trust/employee-holding-resignation-letter-high-angle_23-2149480569.jpg")} className="img-fluid" alt=""/>
                            <h2 className="mt-3">REGISTERED &amp; BONDED</h2>
                            <p>If you have decided to establish a Revocable Living Trust, you can avoid paying high attorney fees, by hiring a Registered and Bonded Legal Document Assistant (LDA). When hiring Legal Document Assistants you can rest assured that registered LDAs hand prepare all documents from start to finish, with software only available to Legal Professionals. We are proud members of CALDA and the BBB.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5 trust" id="legal-service-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h2 className="color-white">Trust Document Preparation in California</h2>
                            <p style={{color:"#fff"}}>When you have to prepare a trust, you will face a large number of decisions, as well as many legal steps that may seem confusing or convoluted. Our team is highly knowledgeable preparing, executing, and funding Living Trusts. You don’t have to worry about any aspect of the living trust process when we help you with the preparation.</p>
                            <p style={{color:"#fff"}}>If you are ready to receive expert help preparing your own trust…</p>
                            <h4>ESTATE PLANNING DOCUMENT PREPARATION</h4>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <div className="container-fluid mt-5 living_trust" id="newsletter-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <h2 style={{color:"#fff"}}>TO ESTABLISH A REVOCABLE LIVING TRUST</h2>
                            <p style={{color:"#fff"}}>Creating a trust proves to be very advantageous even though you may not be a man of great wealth. Trusts have the potential to manage your precious assets and belongings. In fact, it ensures that your property is distributed fairly, as you desire, following your death. On top of that, trusts save a ton of your family’s effort, time, and money.</p>
                            <p style={{color:"#fff"}}>To put it simply, trust refers to a legal paper that is set up by a corporation known as the grantor. A trustee possesses the control or management of the trust. On other hand, the property or the belongings of an individual person or a group held by the trust is called a beneficiary. In a few situations, the grantor himself/herself is the trustee. And in other cases, the grantor is a trustworthy friend or family member.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 bg-white" id="number-section">
                <div className="row">
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/10/one.png" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/image8569-1.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">AVOID PROBATE</h2>
                            <p></p>
                            <p>It is considered to be the critical reason for setting up a trust. Averting probate indicates substantial savings in the case of time, paperwork, and legal fees. Probate is the process that determines the distribution of your property according to your will after death. But, by establishing a trust, you let your descendants bypass this procedure and acquire convenient access to the property and belongings within a short time span. Additionally, with the establishment of a trust, your family does not need to pay huge attorney fees for probate services, probably 5% of your estate’s value, plus tens of thousands of dollars in probate statutory fees and court costs. The probate pr</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/10/two.png" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/image458.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">RESISTANT TO CONTEST	</h2>
                            <p></p>
                            <p>Between a will and a trust, the latter gives you more security when it comes to legal conflict. Sometimes, when a person is not happy with the property distribution, he/she could easily challenge it. In the case of a trust, usually nobody gets a right to do that. And due to this reason, most people consider a trust to be a better option than a will. However, it does not mean that one cannot challenge a trust. Although it is difficult, it is not impossible. A person could challenge the authority of trust in two different ways. Firstly, if someone manages to prove that grantor suffered from a mental illness while establishing trust. On the other side, the trust can also be contested if someone proves that the grantor took such decision in undue influence.</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/06/three-image-1.png" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/12365.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">CONVENIENT DISTRIBUTION</h2>
                            <p></p>
                            <p>Trusts offer flexibility in case of distributing assets. The grantor gets the authority to decide on how his/her possessions will be distributed to the receivers. However, in case the beneficiaries are incapable of managing the property efficiently, the trust allows the grantor to disburse the money in smaller amounts instead of giving the property all at once. Moreover, the grantor can even specify where the beneficiary can spend the money (for instance- food, rent, healthcare, etc.). It will restrict the receiver to spend the entire amount, here and there.</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/06/four-image-1.png" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/deed12.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">CHARITABLE TRUSTS</h2>
                            <p></p>
                            <p>It is a popular process of donating into charitable associations. A grantor possesses the right of transferring his/her property, real estate, etc. to a charitable organization. In the intervening time, the grantor can even keep on using his/her asset. Furthermore, these types of charitable donations are most of the time tax-deductible. Hence, the grantor continues relishing his/her property knowing that in the end it will be used in some worthy-cause.</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/06/five-image.webp" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/confident-entrepreneur-reviewing-contract_1098-3664.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">A FAIR DIVISION OF ASSETS AND PROPERTY</h2>
                            <p></p>
                            <p>In some cases, a difficult situation arrives while distributing assets precisely. In such circumstances, nothing can be as fruitful as a living trust. For instance, in the case of a house, a living trust provides more power and control compared to a will. It clearly mentions the name of the owner and also the person who possess the right of using it. It also states whether the property is allowed to sell, if it is, then how it is to be distributed. In this way, the grantor can make sure how individual beneficiary acquires equal possession over the property. Apart from real estate, other assets that can be placed into the trust include vehicles or boats.</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/06/image-6.webp" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/image896.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">ERADICATE FAMILY FEUDS</h2>
                            <p></p>
                            <p>It is essential to mention that trusts can reduce the potential conflict among the heirs during the settling of an estate. The trust documents can be customized effortlessly for the sake of the grantor. He has the right of detailing the exact items, as well as monetary amounts that are to be given to the individual beneficiary. This helps a lot in eliminating arguments between the heirs. In fact, a trust can be tailored in such a way that a receiver’s spouse cannot interfere in between without his/her consent.</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/06/image-seven.webp" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/front-view-man-working-as-lawyer_23-2151152119.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">MANAGE YOUR AFFAIRS</h2>
                            <p></p>
                            <p>In some cases, a difficult situation arrives while distributing assets precisely. In such circumstances, nothing can be as fruitful as a living trust. For instance, in the case of a house, a living trust provides more power and control compared to a will. It clearly mentions the name of the owner and also the person who possess the right of using it. It also states whether the property is allowed to sell, if it is, then how it is to be distributed. In this way, the grantor can make sure how individual beneficiary acquires equal possession over the property. Apart from real estate, other assets that can be placed into the trust include vehicles or boats.</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4">
                        <div className="product_thumb">
                            <div className="number-box">
                                <img src="https://ldaprolegal.com/wp-content/uploads/2023/06/image-eight.webp" className="img-fluid" alt=""/>
                            </div>
                            <div className="images-thumb">
                                <img src={require("../../assets/images/service/trust/business-people-signing-contract_1098-21026.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <h2 className="mt-3">TRUSTS MAINTAIN CONFIDENTIALITY</h2>
                            <p></p>
                            <p>Unlike a will, trusts provide better privacy. The chief reason behind it is that trusts do not have to go through probate. Hence, there is no room for it to be listed in the public records. Thus, your objective remains totally confidential. Nobody gets to know whom you are leaving your assets.</p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5 trust" id="legal-service-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h2 style={{color:"#fff"}}>ESTATE PLANNING<br/>
                                DOCUMENT PREPARATION
                            </h2>
                            <p style={{color:"#fff"}}>A trust can prevent the probate process, get your assets to your loved ones much more efficiently, and has other benefits during your life as well.</p>
                            <h4>Schedule An Appointment Today 1-844-4LDAPRO</h4>
                            <div className="btns-box mt-4">
                                <Link className="btn-style-three theme-btn" to="/services/living-trust/start-living-trust-online/" id="round-button"><span className="txt">Start Now</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container bg-white" id="lda-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-left-section">
                            <img src={require("../../assets/images/service/trust/law-image.jpg")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-right-section">
                            <h2>WHAT IS A REVOCABLE LIVING TRUST?</h2>
                            <p>Living trusts (also known as a revocable trust, provides a solution for many potential issues associated with estate planning. One of the most important solutions a living trust can provide is protecting your assets, from the ugly probate process. A will cannot protect your assets from the probate process. You should consider many factors before deciding to create a living trust.</p>
                            <p>Living trust, is used to describe a trust that you create during your lifetime. A living trust helps you manage and protect your assets. Living trusts also protect you if you become ill or disabled at any age, after your trust is created. Included in our trust package is a couple important documents that will help you should you become too ill to take care of your own finances or make important decisions regarding your healthcare. Springing Power Of Attorney, appoints an agent to be able to take care of your finances if you’re declared incapacitated. Healthcare Directive is a form used in many hospitals and funeral homes. This document appoints an agent to carry out your wishes for life support and prolonging of life measures. Healthcare directives also provide instructions for your agent regarding after death wishes. Whether you want to be an organ donor, buried, or cremated, the Healthcare Directive provides detailed information regarding your final wishes.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5 bg-white" id="lda-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-right-section">
                            <p>While you are living, the trustee and possibly the co-trustee might also be named. Trustees are responsible for managing the property. The trustees may dispose of or sell assets for their benefit during their lifetime. Upon your death or when you become incapacitated, the successor trustee is directed to either distribute the trust property to your beneficiaries.</p>
                            <p>The successor trustee may continue to hold it and manage it for the benefit of your beneficiaries. Unlike a will, a living trust can provide assistance for managing your property during your lifetime. Living trusts also provide the trustee authority to manage the property and use it for you and your family’s benefit if you become incapacitated, which avoids the need for a guardianship or conservatorship. Both guardianship and conservatorship, may not follow your final wishes and is a very lengthy and costly process. Creating a living trust is one of the most important decisions you can make for you and your family during your lifetime.</p>
                            <p>Creating a trust can be an overwhelming process for many. How do you know which assets to include in the trust? What paperwork do you need and what should it say? These and others are some common questions. Fortunately, the team at Legal Document Assistants can help you every step of the way with our trust preparation services so you can create a trust that is thorough and fair.</p>
                            <div className="btns-box">
                                <Link className="btn-style-three theme-btn" to="/services/living-trust/start-living-trust-online/" id="round-button"><span className="txt">Start Now</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-left-section">
                            <img src={require("../../assets/images/service/trust/living_trust01.png")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <ContactSection/>
            <div className="container-fluid" id="legal-service-section-two" style={{backgroundPosition:"center",backgroundColor: "#d4e3f76b"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h2>ARE YOU INTERESTED IN PREPARING A STAND ALONE WILL?</h2>
                            <p>Whether you have decided to prepare a will, power of attorney, or healthcare directive, you should hire a Registered Legal Document Assistant to give you peace of mind. These documents are important and should not be prepared without the guidance of a legal professional.</p>
                            <p>If you are ready to receive expert help for peace of mind …</p>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <br/>
            <br/>
            <div className="container eviction-bottom-section">
                <div className="row">
                    <div className="col-sm-12">
                        <h2><strong>LDA Pro Legal: Your Affordable Online Estate Planning Document Preparation Partner in California</strong></h2>
                        <p>Planning for the future is essential, especially when it comes to protecting your assets and ensuring your loved ones are taken care of. If you’re seeking affordable and convenient estate planning services in California, look no further than LDA Pro Legal. We specialize in online estate planning document preparation, providing you with the peace of mind that comes from knowing your wishes are legally documented and protected.</p>
                        <h3><strong>Understanding Estate Planning in California</strong></h3>
                        <p>Estate planning involves the creation of legal documents that outline your wishes regarding the distribution of your assets, care for your dependents, and the management of your affairs in the event of incapacity or death. In California, estate planning involves various documents, such as living trusts, wills, powers of attorney, and advance healthcare directives. At LDA Pro Legal, we understand the complexities of estate planning laws in California and are well-versed in the necessary documentation required for a comprehensive estate plan.</p>
                        <h3><strong>Affordable Online Estate Planning Document Preparation</strong></h3>
                        <p>We believe that estate planning should be accessible to everyone, regardless of their budget. At LDA Pro Legal, we offer affordable online estate planning document preparation services that enable you to create a legally binding estate plan without breaking the bank. Our user-friendly online platform allows you to provide the necessary information and preferences from the comfort of your own home. By eliminating costly in-person meetings, we are able to significantly reduce the cost of estate planning, making it more affordable and convenient for you.</p>
                        <h3><strong>Customized Solutions for Your Unique Needs</strong></h3>
                        <p>Every individual’s estate planning needs are unique, and our team at LDA Pro Legal recognizes this. We provide customized solutions tailored to your specific circumstances and goals. Whether you need assistance with creating a revocable living trust, drafting a will, designating powers of attorney, or establishing healthcare directives, we are here to guide you through the process. Our experienced professionals take the time to understand your wishes and objectives, ensuring that your estate planning documents accurately reflect your intentions.</p>
                        <h3><strong>Expert Guidance and Legal Knowledge</strong></h3>
                        <p>Creating an estate plan involves making important decisions that can have long-lasting implications. At LDA Pro Legal, we offer expert guidance and legal knowledge to help you make informed choices. Our team consists of experienced legal document assistants who specialize in estate planning in California. We stay up-to-date with the latest laws and regulations to ensure that your estate plan is compliant and provides the maximum protection for your assets and loved ones. You can trust us to navigate the complexities of estate planning with professionalism and expertise.</p>
                        <h3><strong>Affordability Without Compromising Quality</strong></h3>
                        <p>Our mission at LDA Pro Legal is to provide affordable estate planning services without compromising on quality. While our services are cost-effective, we maintain high standards of accuracy, attention to detail, and professionalism. Our experienced team ensures that your estate planning documents are prepared accurately, meeting all legal requirements and reflecting your specific wishes. We strive to deliver exceptional value for your investment, giving you the confidence that your estate plan is in capable hands.</p>
                        <h3><strong>Confidentiality and Peace of Mind</strong></h3>
                        <p>Confidentiality is a cornerstone of our services at LDA Pro Legal. We understand the sensitive nature of estate planning and handle your personal information with the utmost discretion. You can have peace of mind knowing that your privacy is protected throughout the entire process. Our team maintains the highest level of professionalism and ethical standards to ensure that your estate planning documents are handled with care and confidentiality.</p>
                        <h3><strong>Contact LDA Pro Legal for Affordable Estate Planning in California</strong></h3>
                        <p>If you’re ready to create an affordable and comprehensive estate plan in California, LDA Pro Legal is here to assist you. Our online estate planning document preparation services offer convenience, affordability, and peace of mind. Contact us today to discuss your estate planning needs and let us guide you through the process. With LDA Pro Legal, you can take control of your future and protect your assets and loved ones without the high costs typically associated with estate planning services. <Link to="https://goo.gl/maps/6D2igryyqT57jVDXA">Visit Us Today!</Link></p>
                        <h2 className="text-center my-5"><strong>Frequently Asked Questions</strong></h2>
                        <h3>Why Should I Establish a Living Trust?</h3>
                        <p>Establishing a Living Trust is one of the most important decisions, you can make. Rest assured, when hiring LDA Pro Legal Document Assistants. We are registered and bonded, with many years of experience preparing living trust packages. Revocable Living Trusts protect your family and assets, by avoiding the Probate process. Having the peace of mind that your trust is prepared correctly and funded correctly, is just as important. We have assisted clients for over a decade, preparing Living Trust Packages.</p>
                        <h3>How is the Living Trust Package Prepared?</h3>
                        <p>The living trust packages we provide, are hand prepared, by a Registered Legal Document Assistant. We use a professional legal software, by the name of Fore Trust, that is only available to legal professionals. The software includes all the required language for California, that gets updated yearly to include any new laws. We also provide instructions on how to properly fund your trust and we walk you through every step of the process, to ensure that your Revocable Living Trust serves it’s purpose.</p>
                        <h3>Does a Will Avoid the Probate Process?</h3>
                        <p>No. Revocable Living Trusts that are prepared and funded correctly, avoid the probate process.</p>
                        <h3>What Is the Difference Between Hiring an LDA vs. Do It Yourself Software?</h3>
                        <p>Living Trust Documents must be prepared, executed, and funded correctly, in order for the Trust to serve it’s purpose, which is to avoid probate. We also prepare Probate documents and you would not believe how many clients we have assisted with Probates, because the decedent prepared a “Do It Yourself Trust”.</p>
                        <h3>What Documents are Included in the Living Trust Package?</h3>
                        <p>1. Revocable Living Trust<br/>
                            2. Trust Certification<br/>
                            3. Trust Declaration<br/>
                            4. Pour Over Will<br/>
                            5. Health Care Directive<br/>
                            6. Power of Attorney (Either Effective Immediately or When Deemed Incapacitated, by a Licensed Physician).<br/>
                            7. Real Property Trust Transfer Deed
                        </p>
                        <p>If you have Minor Children, Guardianship paperwork is also included.</p>
                        <h3>What Is the Cost to Prepare a Living Trust Package?</h3>
                        <p>$799 – Individual Person<br/>
                            $899 – Married Couple
                        </p>
                        <p>Once the documents have been reviewed and approved, we schedule the in office notarization and witnessing. $15 per signature notarized, will be charged in office. There are typically about 8 – 10 signatures that will require notarization. There will also be 2 witnesses at the office, to witness the signing, which is required in California.</p>
                        <h3>How Do I Get Started?</h3>
                        <p>You can get started easily online, by clicking the <Link to="https://ldaprolegal.com/services/living-trust/start-living-trust-online/">START NOW</Link> button at the bottom of this email and completing our new client intake form. If you prefer to start in office, we charge $99 to secure the in office appointment.</p>
                        <p>Once your intake form has been submitted, online or in person, please allow us 5 to 7 business days to prepare your documents. We will email the documents for you to review and approve. Once approved, we will place the documents into an estate binder and schedule, in office notarization. We will provide instructions on how to record and properly fund the trust.</p>
                    </div>
                </div>
            </div>
            <section>
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="faq-section">
                                <h2>
                                    Frequently Asked Questions	
                                </h2>
                                <div className="faq scroll scroll1">
                                    <div>
                                        <div className="question">
                                        1. What is a living trust, and why do I need one?
                                        </div>
                                        <div className="answer">
                                            <p>A living trust is a legal document that allows you to manage and protect your assets during your lifetime and ensure a smooth transfer to your beneficiaries after death, bypassing probate. It provides privacy, flexibility, and control over your estate.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        2. How does a living trust differ from a will?
                                        </div>
                                        <div className="answer">
                                            <p>While a will takes effect only after your death and requires probate, a living trust becomes active during your lifetime and allows your assets to be transferred without the need for probate, saving time and money.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        3. What are the benefits of creating a living trust?
                                        </div>
                                        <div className="answer">
                                            <p>Some key benefits of a living trust include:

Avoiding probate court.
Maintaining privacy of your estate.
Reducing delays and costs in asset distribution.
Providing control over how and when assets are distributed to beneficiaries.</p>	
                                        </div>
                                        <div>
                                            <div className="question">
                                            4. Do I still need a will if I have a living trust?
                                            </div>
                                            <div className="answer">
                                                <p>Yes. A “pour-over” will is often used alongside a living trust to cover any assets that were not transferred into the trust during your lifetime, ensuring your wishes are carried out.</p>	
                                            </div>
                                            <div>
                                                <div className="question">
                                                5. How much does it cost to set up a living trust?
                                                </div>
                                                <div className="answer">
                                                    <p>The cost of setting up a living trust varies depending on the complexity of your estate and specific needs. We offer affordable, transparent pricing and personalized consultations to help you determine the best plan for your situation.</p>	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-4">
                            <div className="faq-image">
                                <img src={require("../../assets/images/service/trust/living_trust04.png")} className="img-fluid" alt=""/>	
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
