import { useState } from "react";
import React from 'react';
import Swal from "sweetalert2";

class ContactSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: "",
            Phone: "",
            Email: "",
            Message: ""
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
                const response = await fetch("https://server.ldaprolegal.com/api/v2/form-submit.php", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(this.state),
                });
                const result = await response.json();
                if (result.success) {
                    Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: "Your form has been submitted successfully.",
                    });
                } else {
                    alert("Failed to send message. Please try again.");
                }
            } catch (error) {
                console.error("Error:", error);
                alert("An error occurred.");
            }
    };
    render() {
        
        return (
            <div className="container-fluid" id="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-sm-6">
                            <div className="contact-left-section">
                                <h2>Start Legal Document Services Online</h2>
                                <p>Choose the service you need, complete the easy online intake form, and submit payment to get started. Once the intake form is submitted, all documents are hand prepared by a Registered Legal Document Assistant (LDA). The documents are e-signed and returned to us for filing. Registered Legal Document Assistants prepare all necessary legal documents.</p>
                                <p>We strive to provide a streamlined process to our clients to fulfill all their legal document preparation needs in a quick and efficient manner.</p>
                                <h3>Get In Touch</h3>
                                <div className="call-section">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="calling-icon">
                                                <a href="sms:(916) 620-2446?&body=Hi I might be interested in your service..."><img src="https://ldaprolegal.com/wp-content/themes/SoftMindersTheme/assets/images/call-image1.png" className="img-fluid"/></a>
                                            </div>
                                            <div className="calling-text">
                                                <h4><a href="sms:(916) 620-2446?&body=Hi I might be interested in your service...">TEXT US</a></h4>
                                                <small className="text-white">Say Hi...</small>
                                            </div>
                                        </div>
                                        <span className="line"></span>
                                        <div className="col-xl-6 col-sm-6">
                                            <div className="calling-icon">
                                                <a href="tel:1-844-453-2776"><img src="https://ldaprolegal.com/wp-content/themes/SoftMindersTheme/assets/images/call-image2.png" className="img-fluid"/></a>
                                            </div>
                                            <div className="calling-text">
                                                <h4><a href="tel:1-844-453-2776">CALL US</a></h4>
                                                <small className="text-white">Toll Free</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-right-section">
                                <h2>Complete the form below to get information about our legal document services.</h2>
                                <form onSubmit={this.handleSubmit}>
                                    <input type="hidden" value="CommonContact" name="form-name"></input>
                                    <div className="row">
                                        <div className="col-sm-6 ">
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Name *" type="text" name="Name"  value={this.state.Name} onChange={this.handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Phone *" type="text" name="Phone" value={this.state.Phone} onChange={this.handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <input className="form-control" placeholder="Email *" type="text" name="Email" value={this.state.Email} onChange={this.handleChange} required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                            <textarea rows={10} maxLength={200} className="form-control" aria-invalid="false" placeholder="Message" name="Message" value={this.state.Message} onChange={this.handleChange}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ContactSection;
