import React, {useEffect} from "react";
import OwlCarousel from 'react-owl-carousel';
import {Link} from 'react-router-dom'
import ContactSection from '../components/common/ContactSection'
import Testimonials from '../components/common/Testimonials'


export const Home = (): React.ReactElement => {
    useEffect(() => {
        //jquery code goes here
    });
    const options = {
        responsive: {
          0: {
            items: 1,
          },
          1000: {
            items: 1,
          },
          2000: {
            items: 1,
          }
        },
        nav: true,
        dots: true
      }
    const testimonials = {
        loop:true,
        margin:0,
        nav:true,
        rtl:false,
        smartSpeed: 1000,
        navText: [ '<i className="fa fa-angle-left"></i>', '<i className="fa fa-angle-right"></i>' ],
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            800:{
                items:1
            },
            1024:{
                items:1
            },
            1200:{
                items:1
            }
        }
      }
    
    return (
        <>
            <section className="main-slider">
                <OwlCarousel className='main-slider-carousel owl-carousel owl-theme' loop margin={20} autoplay {...options}>
                    <div className="slide">
                        <div className="auto-container clearfix">
                            <div className="content-column">
                                <div className="inner-column">
                                    <div className="title main-banner-title">LDA PRO LEGAL</div>
                                    <h1>Affordable Legal Documents Prepared<span className="theme-color"> By Registered LDAs.</span></h1>
                                    <div className="text">Legal Documents Assistants Specialize in Reliable, Low Cost Document Preparation.</div>
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="https://ldaprolegal.com/contact-us/"><span className="txt">Free Consultation</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </section>
            <div className="container" id="about-section">
                <div className="row">
                    <div className="col-xl-6  col-sm-12">
                        <div className="image-thumbnail">
                            <img src={require("../assets/images/view-3d-gavel-lawyer-s-day_23-2151023395.jpg")} className="img-fluid" alt="About LDA PRO LEGAL"/>
                        </div>
                        
                    </div>
                    <div className="col-xl-6">
                        <div className="content-thumb">
                            <h2>WHAT IS AN LDA VS. PARALEGAL VS. ATTORNEY?</h2>
                            <p>Legal Document Assistant, LDA is a non-lawyer professional that is authorized to prepare legal documents without an attorney’s supervision. LDAs are a more affordable option to prepare, file, and serve legal documents.</p>
                            <p>If you know what type of documents you need prepared, an LDA is a great choice to save time and money. LDAs have graduated from an American Bar Association approved program, purchased a bond, and registered with the county.</p>
                            <p>Paralegals work under the direct supervision of an attorney and can not charge the public a fee to prepare legal documents.<br/>
                             &nbsp;Attorneys can provide legal advice and represent you in court.</p>
                            <p><span style={{color: "#000000;"}}><strong>If you need legal representation in court, we can refer you to a cooperating attorney.</strong></span></p>
                            <div className="icon-content-box">
                            <div className="row">
                                <div className="col-xl-3 col-6 my-3 b-right">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <a href="/services/eviction/"><img src={require("../assets/images/eviction-icon.webp")} className="img-fluid" alt="Eviction | ldaprolegal"/></a>
                                        </div>
                                        <div className="col-xl-6 pl">
                                            <div className="right-content">
                                                <h3><a href="/services/eviction/">Eviction</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                    <div className="col-xl-3 col-6 my-3 b-right">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <a href="services/probate/"><img src={require("../assets/images/probate-icon.webp")} className="img-fluid" alt="Probate | ldaprolegal"/></a>
                                        </div>
                                        <div className="col-xl-6 pl">
                                            <div className="right-content">
                                                <h3><a href="services/probate/">Probate</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                    <div className="col-xl-3 col-6 my-3 b-right">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <a href="/services/living-trust/"><img src={require("../assets/images/living-trust-icon.webp")} className="img-fluid" alt="Living Trust | ldaprolegal"/></a>
                                        </div>
                                        <div className="col-xl-6 pl">
                                            <div className="right-content">
                                                <h3><a href="/services/living-trust/">Living Trust</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                    <div className="col-xl-3 col-6 my-3 b-right">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <a href="/services/deed-services/"><img src={require("../assets/images/deeds.webp")} className="img-fluid" alt="Deed Services | ldaprolegal"/></a>
                                        </div>
                                        <div className="col-xl-6 pl">
                                            <div className="right-content">
                                                <h3><a href="/services/deed-services/">Deed Services</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                    <div className="col-xl-3 col-6 my-3 b-right">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <a href="/services/divorce/"><img src={require("../assets/images/divorce-icon.webp")} className="img-fluid" alt="Divorce | ldaprolegal"/></a>
                                        </div>
                                        <div className="col-xl-6 pl">
                                            <div className="right-content">
                                                <h3><a href="/services/divorce/">Divorce</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                    <div className="col-xl-3 col-6 my-3 b-right">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <a href="/about-us/"><img src={require("../assets/images/about-us-icon.webp")} className="img-fluid" alt="About Us | ldaprolegal"/></a>
                                        </div>
                                        <div className="col-xl-6 pl">
                                            <div className="right-content">
                                                <h3><a href="/about-us/">About Us</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                </div>
                        </div>
                            <div className="btns-box">
                                <a className="btn-style-three theme-btn" href="/about-us/" id="round-button"><span className="txt">Read More</span></a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid" id="who_are_section">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section-box">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="left_section">
                                        <h2>LDA PRO LEGAL LEGAL DOCUMENT ASSISTANTS</h2>
                                        <p>Our services are often utilized by people who represent themselves or who don’t need legal advice but need legal documents prepared. We are a team of paralegals and LDAs who are registered and bonded to prepare legal documents.</p>
                                        <div className="btns-box mt-5">
                                            <Link className="btn-style-three theme-btn" to="/testimonials" id="round-button"><span className="txt">Clients Testimonials</span></Link>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="right_section">
                                        <img src={require("../assets/images/home/man-office-writing-paper_23-2148377785.jpg")} className="img-fluid" alt=""/>
                                        <div className="right_box">
                                            <div className="title">
                                                <h4>WHO <br/>WE ARE</h4>
                                            </div>
                                            <div className="icon"><img src={require("../assets/images/home/lda.webp")} className="img-fluid" alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container bg-white" id="about_section">
                <div className="row">
                    <div className="col-xl-5">
                        <div className="about_left">
                            <img src={require("../assets/images/home/angela-lda-pro.webp")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="about_right_content">
                            <h2>
                                <strong>ANGELA JONES,</strong>  L.D.A., U.D.A.
                            </h2>
                            <p>
                            Angela Jones, CEO, of LDA PRO Legal, specializes in Real Estate Document Preparation Services, such as: Eviction, Probate, Living Trust, and Deed Transfer.
                            </p>
                            <p>
                            In 2007, she began her career as a Realtor and Property Manager. Years later, she attended American River College, graduated with honors, and received an associate’s degree in Legal Studies and Language Studies.	
                            </p>
                            <p>
                            In 2016, she became a Registered Legal Document Assistant and Registered Unlawful Detainer Assistant, to provide affordable legal document services to the public. 	
                            </p>
                            <p>
                            LDA PRO Legal incorporates a business model that provides clients with affordable and reliable legal document preparation services, while implementing an easy, streamline process. 	
                            </p>
                            <p>
                            Clients can hire LDA Pro Legal, by completing the easy online intake form. After submitting the intake form our Registered Legal Document Assistants, are here to assist with your preparation needs, anytime, anywhere.	
                            </p>
                            <p>
                            LDA Pro Legal is also proud to offer access to our cooperating attorney network if you need legal representation in court. 	
                            </p>
                            <p>
                            LDA # 2016-01
                            UDA # 2017-01
                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>









            
            <div className="container mt-5 bg-white" id="services_section">
                <div className="row">
                    <div className="col-xl-12 text-center">
                        <div className="separator">
                            <div className="line"></div>
                            <h2>Legal Document Preparation Services</h2>
                            <div className="line"></div>
                        </div>
                        <p><b>LDA PRO LEGAL's Registered Legal Document Assistants</b></p>
                    </div>
                </div>
                <div className="row mt-3 service-box-flex">
                    <div className="col-xl-6 col-sm-6 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/home/businessman-reading-contract-closeup_1098-14742.jpg")} className="img-fluid" style={{width:"100%"}} alt="EVICTION Service | LDA PRO LEGAL"/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/home/eviction-service-icon.webp")} className="img-fluid" alt="EVICTION icon"/>
                            </div>
                            <div className="service-content">
                                <h2>EVICTION</h2>
                                <p>Landlords Utilize Our Services for Eviction Notice and  Unlawful Detainer Document Preparation, up until the Sheriff Performs the Lockout.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/eviction/" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/eviction/start-eviction-online/" id="round-button" style={{backgroundColor: "#114489"}}><span className="txt">Start Online</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/home/business-people-signing-contract_1098-21026.jpg")} className="img-fluid" style={{width:"100%"}} alt="PROBATE Service | LDA PRO LEGAL"/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/home/probate-service-icon.webp")} className="img-fluid" alt="PROBATE icon"/>
                            </div>
                            <div className="service-content">
                                <h2>PROBATE</h2>
                                <p>Providing Affordable Probate Document Preparation to Finalize a Person's Legal and Financial Affairs After Death.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/probate/" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/probate/start-probate-online/" id="round-button" style={{backgroundColor: "#114489"}}><span className="txt">Start Online</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/home/black-woman-pointing-document-near-lady-with-pen-table-with-calculator-gavel_23-2148042590-1.jpg")} className="img-fluid" style={{width:"100%"}} alt="LIVING TRUST Service | LDA PRO LEGAL"/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/home/living-trust-service-icon.webp")} className="img-fluid" alt="LIVING TRUST icon"/>
                            </div>
                            <div className="service-content">
                                <h2>LIVING TRUST</h2>
                                <p>Living Trust Package Includes: Revocable Living Trust, Trust Cert, Will, Healthcare Directive, Power of Attorney, Trust Transfer Deed, and Funding Instructions.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/living-trust/" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/living-trust/start-living-trust-online/" id="round-button" style={{backgroundColor: "#114489"}}><span className="txt">Start Online</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/home/happy-young-asian-couple-realtor-agent_7861-1032.jpg")} className="img-fluid" style={{width:"100%"}} alt="DEED SERVICES Service | LDA PRO LEGAL"/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/home/deed-transfer-service-icno.webp")} className="img-fluid" alt="DEED SERVICES icon"/>
                            </div>
                            <div className="service-content">
                                <h2>DEED SERVICES</h2>
                                <p>Transferring real estate title in California, is a straightforward process accomplished through the use of a property deed. After you have determined the right type of deed for your transaction, it is extremely important that the deed is prepared by professionals to avoid cloud on title. Simply complete our online intake form and we handle the rest. We pull the property’s title and make sure
                                </p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/deed-transfer/" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/deed-services/start-deed-services-online/" id="round-button" style={{backgroundColor: "#114489"}}><span className="txt">Start Online</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*<div className="col-xl-6 col-sm-6 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/home/SmallClaims.png")} className="img-fluid" style={{width:"100%"}} alt="Small Claims | LDA PRO LEGAL"/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/home/SmallClaimsIcon.png")} className="img-fluid" alt="Small claims icon"/>
                            </div>
                            <div className="service-content">
                                <h2>SMALL CLAIMS</h2>
                                <p>Small Claims Document Preparation in Sacramento, California and Surrounding Counties.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/small-claims" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/small-claims/start-small-claims-online/" id="round-button" style={{backgroundColor: "#114489"}}><span className="txt">Start Online</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="col-xl-6 col-sm-6 mt-3">
                        <div className="service-thumbnail-box">
                            <div className="service-box-image">
                                <img src={require("../assets/images/home/DivorceImage.png")} className="img-fluid" style={{width:"100%"}} alt="Divorce | LDA PRO LEGAL"/>
                            </div>
                            <div className="service-icon">
                                <img src={require("../assets/images/home/DivorceIcon.png")} className="img-fluid" alt="Divorce icon"/>
                            </div>
                            <div className="service-content">
                                <h2>DIVORCE</h2>
                                <p>Divorce Filing Document Preparation in Sacramento, California and Surrounding Counties.</p>
                                <div className="button-section" id="service-button">
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="/services/divorce-filing" id="round-button"><span className="txt">Learn More</span></Link>
                                    </div>
                                    <div className="btns-box">
                                        <Link className="btn-style-three theme-btn" to="https://ldaprolegal.com/services/divorce/start-divorce-filing-online/" id="round-button" style={{backgroundColor: "#114489"}}><span className="txt">Start Online</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5" id="help-section">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="image-box-section"><img src={require("../assets/images/home/brunette-businesswoman-writing-document_23-2148142696.jpg")} className="img-fluid" alt="WE CAN HELP"/></div>
                    </div>
                    <div className="col-xl-6">
                        <div className="content-box-section">
                            <h2>WE CAN HELP</h2>
                            <p>Our goal is to ensure that you have legal documents completed for whatever your situation is to eliminate stress, save time, and fulfill your needs. Whether you need to create a living trust, probate an estate, or create a living trust, LDA PRO LEGAL is here to assist you throughout the entire process. LDA PRO LEGAL, provides reliable document preparation services, without breaking your pocket book.</p>
                            <p><strong>Choose Legal Document Assistants for Legal Document Services throughout California.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container bg-white" id="document-section">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="separator">
                            <div className="line"></div>
                            <h2>LEGAL DOCUMENT ASSISTANTS</h2>
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-xl-4 col-sm-6">
                        <div className="document-thumbnail">
                            <div className="image--thumb">
                                <img src={require("../assets/images/home/closeup-shot-person-writing-book-with-gavel-table_181624-57173.jpg")} className="img-fluid" alt="What We Do? | LDA PRO LEGAL"/>
                                <div className="icon-icon"><img src={require("../assets/images/home/icons1.webp")} className="img-fluid" alt="What We Do? icon"/></div>
                            </div>
                            <div className="content--thumb">
                                <h2>What We Do?</h2>
                                <p>LDA PRO LEGAL consists of Registered Legal Document Assistants, Unlawful Detainer Assistants, and Process Servers, with years of experience. We can help prepare legal documents online or schedule an appointment in office.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="document-thumbnail">
                            <div className="image--thumb">
                                <img src={require("../assets/images/home/view-professional-business-people-working-together_23-2150917010-1.jpg")} className="img-fluid" alt="Professional & Experienced | LDA PRO LEGAL"/>
                                <div className="icon-icon"><img src={require("../assets/images/home/icons2.webp")} className="img-fluid" alt="Professional & Experienced icon"/></div>
                            </div>
                            <div className="content--thumb">
                                <h2>Professional & Experienced</h2>
                                <p>For over a decade, LDA PRO LEGAL has provided legal document preparation services throughout Northern California. We are proud members of the California Association of Legal Document Assistants, CALDA.org. We are accredited with an A+ Rating at the Better Business Bureau.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="document-thumbnail">
                            <div className="image--thumb">
                                <img src={require("../assets/images/home/man-making-his-move_53876-64862.jpg")} className="img-fluid" alt="Affordable Prices Reliable Service | LDA PRO LEGAL"/>
                                <div className="icon-icon"><img src={require("../assets/images/home/icons3.webp")} className="img-fluid" alt="Affordable Prices Reliable Service icon"/></div>
                            </div>
                            <div className="content--thumb">
                                <h2>Affordable Prices and Reliable Service</h2>
                                <p>LDA PRO LEGAL specializes in preparing, filing, and serving legal documents. Our team of Registered Legal Document Assistants, Unlawful Detainer Assistants, and Process Servers, have years of experience. When choosing us, you will save time and money.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="video-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-6">
                        <div className="left_video-content">
                            <p>Find out how a Registered Legal Document Assistant can help you save time and money, when you need legal documents prepared.</p>
                            <h2>FOR MORE INFORMATION</h2>
                            <Link to="tel:(844) 453-2776" className="btn call-us-button">Call Us</Link>
                            <Link to="/contact-us/" className="btn start-now-button">Start Now</Link>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                        <div className="right_video">
                            <OwlCarousel  className=" owl-carousel owl-theme" id="videosection" {...testimonials}>
                                <div className="item">
                                    <Link to="https://www.youtube.com/embed/_q_sccfumMw" target="_Blank"><img className="d-block w-100" src={require("../assets/images/home/video2.webp")} alt="PRO TIP: PARALEGAL, LDA, OR ATTORNEY"/></Link>
                                    <div className="slider-box">
                                        <h3>PRO TIP: PARALEGAL, LDA, OR ATTORNEY</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <Link to="https://www.youtube.com/embed/YITieMftd-U" target="_Blank"><img className="d-block w-100" src={require("../assets/images/home/image5623.webp")} alt="LDA PRO LEGAL LEGAL DOCUMENT ASSISTANTS INFOMERCIAL"/></Link>
                                    <div className="slider-box">
                                        <h3>LDA PRO LEGAL LEGAL DOCUMENT ASSISTANTS INFOMERCIAL</h3>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container" id="testimonails-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-6 hidden-sm">
                        <div className="client-image">
                            <img src={require("../assets/images/home/pexels-photo-5668481.webp")} className="img-fluid" alt="Angela Jones"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                        <div className="slider-part">
                            <h3>What People Say</h3>
                            <h2>About LDA PRO LEGAL</h2>
                        </div>
                        <Testimonials/>
                    </div>
                    <img src={require("../assets/images/home/quote.webp")} className="img-fluid quote" alt=""/>
                </div>
            </div>
            <ContactSection />
            <div className="container" id="blog-section">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-xl-12 text-center">
                                <div className="separator">
                                    <div className="line"></div>
                                    <h2>LATEST NEWS</h2>
                                    <div className="line"></div>
                                </div>
                                <p><b>Legal Document Assistants Specialize In Estate Document Preparation Services.</b></p>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-4 col-sm-6">
                                <div className="main-blog-box">
                                    <div className="blog-thumbnail">
                                        <Link to="https://ldaprolegal.com/sacramento-eviction-service/"><img src={require("../assets/images/home/lda-eviction.jpg")} className="img-fluid" alt=""/></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h3><Link to="https://ldaprolegal.com/sacramento-eviction-service/">LDA Pro’s Sacramento Eviction Service: Enjoy A Smooth Eviction Of Your Tenant</Link></h3>
                                        <p>Eviction can be a difficult and stressful process. Specifically when navigating the&hellip;</p>
                                        <hr/>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                            </svg>
                                            October 15, 2024							
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="main-blog-box">
                                    <div className="blog-thumbnail">
                                        <Link to="https://ldaprolegal.com/eviction-specialist-near-me/"><img src={require("../assets/images/home/lda-eviction-3.jpg")} className="img-fluid" alt=""/></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h3><Link to="https://ldaprolegal.com/eviction-specialist-near-me/">LDA Pro’s Eviction Specialist Near Me: Your Guide To A Smooth Process</Link></h3>
                                        <p>Eviction can be a hectic and time-consuming process. Navigating the legal intricacies&hellip;</p>
                                        <hr/>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                            </svg>
                                            September 30, 2024							
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="main-blog-box">
                                    <div className="blog-thumbnail">
                                        <Link to="https://ldaprolegal.com/paralegal-landlord-and-tenant/"><img src={require("../assets/images/home/lda-blog.jpg")} className="img-fluid" alt=""/></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h3><Link to="https://ldaprolegal.com/paralegal-landlord-and-tenant/">How LDA Pro Legal’s Paralegal Landlord And Tenant Service Simplifies Evictions</Link></h3>
                                        <p>Eviction Can be a difficult and stressful procedure, particularly for landlords who&hellip;</p>
                                        <hr/>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                            </svg>
                                            September 24, 2024							
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 text-center">
                                <div className="btns-box">
                                    <Link className="btn-style-three theme-btn" to="/blogs/" id="round-button"><span className="txt">View More Blogs</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="lda-section">
                <div className="row mb-5">
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-left-section">
                            <img src={require("../assets/images/home/home_01.png")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-right-section">
                            <h2>LDAs ARE PROFESSIONAL AND AFFORDABLE</h2>
                            <p>LDA PRO LEGAL specializes in many different areas of legal document preparation services such as: Eviction, Unlawful Detainer, Living Trust, and Probate.</p>
                            <p>We are proud members of the California Legal Document Association, CALDA. CALDA is the time-honored organization for legal document preparation professionals and supporters of this profession. </p>
                            <div className="btns-box">
                                <Link className="btn-style-three theme-btn" to="https://calda.org/" id="round-button"><span className="txt">CALDA.ORG</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
