import React, {useEffect} from "react";
import {Link} from "react-router-dom"
import ContactSection from '../../components/common/ContactSection'
import CTA from '../../components/common/CTA'

export const Probate = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });
    
    return (
        <>
            <div className="container-fluid" id="inner-banner">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-xl-12">
                            <div className="about-content">
                                <div className="breadcrumbs">
                                    <div className="title">LDA PRO LEGAL</div>
                                    <h1>Probate Without<span className="theme-color"> Expensive <br/>Legal Fees</span></h1>
                                    <div className="text" id="txt">Probate Services in Sacramento, California and Surrounding Counties.</div>
                                    <p className="mt-2">
                                        <React.Fragment>
                                            <Link to="/services/probate/start-probate-online/" className="btn-style-three theme-btn" id="round-button">
                                            <span className="txt">START NOW</span></Link>
                                        </React.Fragment>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container bg-white mb-5" id="seven-section">
                <div className="row">
                    <div className="col-sm-4 mb-5">
                        <div className="price-section-left">
                            <h2>SMALL ESTATE</h2>
                            <h4>AFFIDAVIT</h4>
                            <div className="price">$1499</div>
                            <p>Estate Value Up To $166,249</p>
                            <div id="button-box" className="btns-box mt-4"><Link id="round-button" className="btn-style-three theme-btn" to="/services/probate/start-probate-online/"><span className="txt">START NOW</span></Link></div>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                        <div className="price-section-left">
                            <h2>PROBATE FULL</h2>
                            <h4>SERVICE</h4>
                            <div className="price">$3499</div>
                            <p>PAYMENT PLANS AVAILABLE</p>
                            <div id="button-box" className="btns-box mt-4"><Link id="round-button" className="btn-style-three theme-btn" to="/services/probate/start-probate-online/"><span className="txt">START NOW</span></Link></div>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                        <div className="price-section-left">
                            <h2>EX PARTE</h2>
                            <h4>RUSH FILING</h4>
                            <div className="price">$399</div>
                            <p>Documents will be prepared within 2 to 3 business days and hearing set within about a week.</p>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                        <div className="price-section-left">
                            <h2>FINAL PROBATE </h2>
                            <h4>ACCOUNTING</h4>
                            <div className="price">STARTS $699</div>
                            <p>Additional court fees may be required.</p>
                        </div>
                    </div>
                    <div className="col-sm-4 mb-5">
                        <div className="price-section-left">
                            <h2>PROBATE ADDTIONAL</h2>
                            <h4>FEES</h4>
                            <div className="price">$69</div>
                            <p>each by mail service</p>
                            <h4>$99 each for personal service</h4>
                            <p>Service of Process for Each Person Served</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container mt-5 bg-white" id="eviction-section">
                <div className="row">
                    <div className="col-xl-4 mt-4" id="service-box">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/probate/front-view-man-working-as-lawyer_23-2151152119.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>PROBATE SERVICES	</h2>
                                <p>Probate is a legal proceeding that is used to wind up a person’s legal and financial affairs after death. In California probate proceedings are conducted in the Superior Court for the county in which the decedent lived, and can take at least eight months and sometimes as long as several years, if the matter becomes contested.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 mt-4" id="service-box">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/probate/people-sitting-desk-working_23-2147650936.jpg")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>DO I NEED A PROBATE ATTORNEY?</h2>
                                <p>If you want to file a probate in California, the probate law does not require you to hire an attorney to settle the estate. The average simple estate can often be settled using the guidelines of self-help materials and the services of a Registered Legal Document Assistant to prepare your probate paperwork.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 mt-4" id="service-box">
                        <div className="eviction-thumbnail">
                            <div className="eviction-image">
                                <img src={require("../../assets/images/service/probate/probate_11.png")} className="img-fluid" alt=""/>
                            </div>
                            <div className="eviction-content">
                                <h2>PROBATE COURT FEES</h2>
                                <h4>Price does not include:</h4>
                                <ul>
                                    <li>Mandatory court filing fee for Initial Petition $435</li>
                                    <li>Newspaper Publication Fees</li>
                                    <li>Probate Referee Fee: 1/10th of 1% of the total assets appraised, which is paid by the estate.</li>
                                    <li>Mandatory court filing fee for Final Petition for Distribution $435</li>
                                    <b>Complete our online form or schedule an appointment to get started today! 1-844-4LDAPRO</b>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <div className="container-fluid bg-white" id="lda-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-left-section">
                            <img src={require("../../assets/images/service/probate/Probate_img03.png")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="lda-right-section">
                            <h2>THE PROBATE PROCESS</h2>
                            <p>In some cases, the Probate Court may oversee the division of property of someone who has died. This property is called a decedent’s estate. The court supervises the assets and liabilities of people who die while they are residents of California or who leave property inside the state. This includes payment of the dead person’s debts and the distribution of property to beneficiaries.</p>
                            <p>A dead person’s estate will not be handled in probate court if there is a surviving spouse and the estate consists entirely of community property, or the dead person’s property is held in joint tenancy with another person. Property transferred by gift before death, or placed into certain types of living trusts, also is not subject to probate. Whether the court must be involved depends on a number of things. These can include whether the person was married at the time of death, the type and value of the property the person owned and other things.</p>
                            <p>The estate can include personal property, such as money in the bank, jewelry or a car. It can also include real property, like the person’s home. Often, the estate has both personal and real property, like the person’s home. If the person has written a Will at the time of death, s/he is said to have died testate. If there was no Will, the person is said to have died intestate. The probate court watches over cases whether the person was testate or intestate.</p>
                            <p>Hiring LDA PRO for Eviction Services, will save you time, money, and heartache!</p>
                            <div className="btns-box mt-4"><Link id="round-button" className="btn-style-three theme-btn" to="/services/probate/start-probate-online/"><span className="txt">START NOW</span></Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="provisions-part">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 order-2 order-xl-1">
                            <div className="provisions-section">
                                <h2>NO WILL EXISTS	</h2>
                                <p style={{color:"#000"}}>If no Will exists, the property (estate) is divided among the person’s heirs. In California, if the person has a spouse and/or children, the property first goes to them. If there is no spouse or children, the property goes to the person’s next nearest relatives. In these cases, the court will appoint an administrator to manage and distribute the assets. This person is known as the executor.</p>
                                <h2>SMALL ESTATE AFFIDAVIT</h2>
                                <p style={{color:"#000"}}>Sometimes a full probate proceeding is not needed. This would be true if the estate is not worth more than $166,249. Another example would be if everything goes to the person’s spouse.</p>
                            </div>
                        </div>
                        <div className="col-xl-5 order-1 order-xl-2">
                            <div className="provisions-image">
                                <img src={require("../../assets/images/service/probate/view-professional-business-people-working-together_23-2150917010-1.jpg")} className="img-fluid" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <div className="container-fluid bg-white" id="lda-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-sm-12">
                            <div className="lda-left-section">
                                <img src={require("../../assets/images/service/probate/probate_img01.png")} className="img-fluid" alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="lda-right-section">
                                <h2>PETITION FOR PROBATE</h2>
                                <p>This is what is filed if the person had a Will but did not name an executor. It is also used when the person named in the Will is either deceased or does not want to act as the executor.</p>
                                <h3>A Petition for Letters of Administration:</h3>
                                <p>This is filed if the person died without a Will. The person filing it is asking the court to have an administrator appointed to act as personal representative of the estate.</p>
                                <h3>A Petition for Letters of Special Administration:</h3>
                                <p>A petition to authorize limited acts on behalf of the estate pending issuance of permanent letters, or to authorize permanent powers pending a will contest. This petition can be heard and granted ex parte if it is not contested.</p>
                                <p>The Probate Court issues Letters Testamentary or Letters of Administration naming the executor or administrator. During the administration of the estate, certified copies of these letters may be needed by banks, title companies, tax authorities, and others.</p>
                                <div className="btns-box mt-4"><Link id="round-button" className="btn-style-three theme-btn" to="/services/probate/start-probate-online/"><span className="txt">START NOW</span></Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="provisions-part">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 order-2 order-xl-1">
                            <div className="provisions-section">
                                <p style={{color:"#000"}}>The law requires publication of a Notice of Petition to Administer Estate. This is a Notice to all creditors to file their claims against the estate. Creditors usually have four months to file their claims.</p>
                                <p style={{color:"#000"}}>Probate cases tend to move slowly. Many detailed steps are required to ensure that all creditors are paid, all property is identified, all taxes are paid, and title to each asset is properly transferred. Typically, it takes four to six weeks after the decedent’s death to appoint an executor or administrator. Even in the most routine probates, the law requires a minimum four-month wait after the Notice to Creditors has been issued before any action can be taken to distribute or close the estate. If the case requires the preparation and filing of a federal estate tax return, the process can be expected to take even longer. The financial circumstances of each decedent vary widely, so some estates may require much more court involvement than others, which can increase the time for an estate case to be completed.</p>
                            </div>
                        </div>
                        <div className="col-xl-5 order-1 order-xl-2">
                            <div className="provisions-image">
                                <img src={require("../../assets/images/service/probate/probate_img02.png")} className="img-fluid" alt="Just Cause Provisions"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTA/>
            <br/>
            <br/>
            <div className="container eviction-bottom-section">
                <div className="row">
                    <div className="col-sm-12">
                        <h2><strong>LDA Pro Legal: Your Trusted Partner for Affordable Probate Services in California</strong></h2>
                        <p>Probate proceedings in California can be complex, time-consuming, and expensive. If you’re in need of probate services but are concerned about the cost, look no further than LDA Pro Legal. We specialize in providing affordable probate services in California, offering a streamlined online process to save you time, effort, and money.</p>
                        <h3><strong>Understanding Probate in California</strong></h3>
                        <p>Probate is the legal process through which a deceased person’s assets are distributed to beneficiaries or heirs and any outstanding debts are resolved. California has specific probate laws and procedures that must be followed to ensure a smooth administration of the estate. At LDA Pro Legal, we have a thorough understanding of California’s probate laws and stay up-to-date with any changes in legislation. Our experienced team is well-equipped to guide you through the probate process efficiently and affordably.</p>
                        <h3><strong>Affordable Online Document Processing</strong></h3>
                        <p>At LDA Pro Legal, we believe that access to affordable probate services should be available to everyone. That’s why we offer an online document processing service that helps you save on costly attorney fees while ensuring that the necessary legal documents are prepared accurately and professionally. Our user-friendly online platform allows you to fill out the required forms and provide us with the necessary information from the comfort of your own home or office. This streamlined process helps expedite the probate proceedings, ultimately saving you time and money.</p>
                        <h3><strong>Customized Support Tailored to Your Needs</strong></h3>
                        <p>Every probate case is unique, and at LDA Pro Legal, we provide customized support tailored to your specific needs. Whether you require assistance with probate administration, filing court documents, or navigating the complexities of the probate process, our experienced team is here to guide you every step of the way. We take the time to understand your circumstances and provide personalized assistance to ensure that your probate case is handled effectively and efficiently.</p>
                        <h3><strong>Expert Guidance and Legal Assistance</strong></h3>
                        <p>Navigating the probate process can be overwhelming, especially during an already emotionally challenging time. With LDA Pro Legal by your side, you have access to expert guidance and legal assistance. Our team consists of experienced legal document assistants who specialize in probate services in California. We are well-versed in the legal requirements, deadlines, and procedures involved in probate proceedings. We can help you navigate the complexities of the probate process with professionalism and expertise, ensuring that all necessary documents are prepared accurately and in compliance with California probate laws.</p>
                        <h3><strong>Transparent and Affordable Pricing</strong></h3>
                        <p>We understand the importance of transparent and affordable pricing when it comes to probate services. At LDA Pro Legal, we offer competitive and affordable rates for our services. We provide upfront pricing information, so you know exactly what to expect without any hidden fees or surprises along the way. Our goal is to deliver exceptional value for your investment, providing you with high-quality probate services at a fraction of the cost of traditional legal representation.</p>
                        <h3><strong>Confidentiality and Professionalism</strong></h3>
                        <p>Confidentiality and professionalism are of utmost importance to us at LDA Pro Legal. We handle your probate case with the highest level of discretion, respecting your privacy and the sensitive nature of the proceedings. Our team maintains the highest standards of professionalism and ethics, ensuring that your probate case is handled with care, integrity, and confidentiality.</p>
                        <h3><strong>Contact LDA Pro Legal for Affordable Probate Services in California</strong></h3>
                        <p>When you need affordable probate services in California, LDA Pro Legal is here to help. Our online document processing services, combined with our legal expertise, make the probate process more accessible and affordable. Contact us today to discuss your probate needs and let us assist you with professionalism and efficiency. With LDA Pro Legal, you can navigate the probate process in California with confidence, knowing that you have a trusted partner by your side, offering affordable solutions tailored to your needs. <Link to="https://goo.gl/maps/6D2igryyqT57jVDXA">Visit Us Today!</Link></p>
                    </div>
                </div>
            </div>
            <section>
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="faq-section">
                                <h2>
                                    Frequently Asked Questions	
                                </h2>
                                <div className="faq scroll scroll1">
                                    <div>
                                        <div className="question">
                                        1. What are probate services, and how can you help?
                                        </div>
                                        <div className="answer">
                                            <p>Probate services involve managing the legal process of administering a deceased person’s estate, including validating their will, paying debts, and distributing assets to beneficiaries. We provide expert assistance to guide you through every step of this process efficiently and stress-free.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        2. Do I need probate if there’s a will?
                                        </div>
                                        <div className="answer">
                                            <p>Yes, even if a valid will exists, probate is usually required to legally transfer ownership of the deceased person’s assets to their beneficiaries. We help ensure the process is carried out correctly and in accordance with state laws.</p>	
                                        </div>
                                    </div>

                                    <div>
                                        <div className="question">
                                        3. How long does the probate process take?
                                        </div>
                                        <div className="answer">
                                            <p>The probate timeline depends on the size and complexity of the estate. On average, it can take anywhere from a few months to over a year. We work diligently to help you resolve probate matters as quickly as possible.

</p>	
                                        </div>
                                        <div>
                                            <div className="question">
                                            4. What documents do I need to start the probate process?
                                            </div>
                                            <div className="answer">
                                                <p>To begin, you will typically need:

A certified copy of the death certificate.
The original will (if one exists).
A list of assets and liabilities of the estate.
Contact information for beneficiaries and creditors.
We’ll guide you on gathering any additional documents required for your case.</p>	
                                            </div>
                                            <div>
                                                <div className="question">
                                                5. How much do probate services cost?
                                                </div>
                                                <div className="answer">
                                                    <p>The cost of probate services varies depending on the estate's size and complexity. We provide transparent pricing and personalized quotes tailored to your situation. Contact us today for a free consultation.</p>	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-4">
                            <div className="faq-image">
                                <img src={require("../../assets/images/service/probate/probate_img04.png")} className="img-fluid" alt=""/>	
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactSection/>
        </>
    );
};
