import React, {useEffect} from "react";
import {Link} from 'react-router-dom'
import ContactSection from '../components/common/ContactSection'
import Testimonials from "../components/common/Testimonials"

export const About = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });

    return (
        <>
            <div className="container bg-white" id="about_section">
                <div className="row">
                    <div className="col-xl-5">
                        <div className="about_left">
                            <img src={require("../assets/images/about/angela-lda-pro.webp")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="about_right_content">
                            <h2><strong>ANGELA JONES,</strong> L.D.A., U.D.A., CEO</h2>
                            <p>Angela Jones, CEO, of LDA PRO Legal, specializes in Real Estate Document Preparation Services, such as: Eviction, Probate, and Living Trust.</p>
                            <p>In 2007, she began her career as a Realtor and Property Manager. Years later, she attended American River College, graduated with honors, and received an associate’s degree in Legal Studies and Language Studies.</p>
                            <p>In 2016, she became a Registered Legal Document Assistant and Registered Unlawful Detainer Assistant, to provide affordable Legal document services to the public.</p>
                            <p>LDA PRO Legal incorporates a business model that provides clients with affordable and reliable legal document preparation services, while implementing an easy, streamline process.</p>
                            <p>Clients can hire LDA Pro Legal, by completing the easy online intake form. After submitting the intake form our Registered Legal Document Assistants, are here to assist with your preparation needs, anytime, anywhere.</p>
                            <p>LDA Pro Legal is also proud to offer access to our cooperating attorney network if you need legal representation in court.</p>
                            <p>
                                LDA # 2016-01<br/> 
                                UDA # 2017-01.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container bg-white" id="about-section" style={{top: "45px"}}>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="content-thumb" style={{marginTop:"0px"}}>
                            <h2>WHAT IS AN LDA VS. PARALEGAL?</h2>
                            <p>Legal Document Assistant, LDA is a non-lawyer professional that is authorized to prepare legal documents without an attorney’s supervision. LDAs are a more affordable option to prepare, file, and serve legal documents, if you do not need legal advice. LDAs have graduated from an American Bar Association approved program, purchased a bond, and registered with the county.</p>
                            <p>LDAs cannot provide legal advice and cannot represent you in court. LDAs can only prepare legal documents at the client’s direction. Laws concerning Legal Document Assistants are set forth in the California Business and Professions Code (BPC 6400-6415).</p>
                            <p>Paralegals differ from LDAs because they do not require education, filing a bond, or registration. Paralegals are not permitted to assist the public with preparing legal forms and charging a fee. Paralegals are required to work for an attorney and directly under an attorney’s supervision.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="image-thumbnail">
                            <img src={require("../assets/images/about/client-meeting.webp")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5 bg-white about_help_section" id="help-section">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="image-box-section">
                            <img src={require("../assets/images/about/eviction.jpg")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="content-box-section">
                            <h2>WE CAN HELP</h2>
                            <p>Our goal is to ensure that you have legal documents completed for whatever your situation is to eliminate stress, save time, and fulfill your needs. Whether you need to create a living trust, probate an estate, or create a living trust, LDA Pro Legal is here to assist you throughout the entire process. LDA Pro Legal provides reliable estate document preparation services, without breaking your pocketbook.</p>
                            <p><strong>Choose Legal Document Assistants for Legal Document Services throughout California.</strong></p>
                            <div id="about-section-button" className="btns-box mt-5">
                                <Link id="round-button" className="btn-style-three theme-btn" to="contact/">
                                    <span className="txt">Contact Now</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacing"></div>
            <div className="container" id="testimonails-section">
                <div className="row">
                    <div className="col-xl-6 col-sm-6">
                        <div className="client-image">
                            <img src={require("../assets/images/about/clint-angela.jpg")} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                        <div className="slider-part">
                            <h3>What People Say</h3>
                            <h2>About LDA PRO LEGAL</h2>
                        </div>
                        <Testimonials/>
                    </div>
                    <img src={require("../assets/images/home/quote.webp")} className="img-fluid quote" alt=""/>
                </div>
            </div>

            <ContactSection/>
        </>
    );
};
