import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
const BoneLayoutDefault = ({ children, className, type, pageTitle }) => (
	<div className={className}>
		<Header type={type} pageTitle={pageTitle} className="invert-color" navPosition="right" />
		<main className="site-content">{children}</main>
		<ScrollToTop/>
		<Footer />
	</div>
);

export default BoneLayoutDefault;
