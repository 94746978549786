import React, { useEffect } from "react";

export const StartEviction = (): React.ReactElement => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://form.jotform.com/jsform/243518624295159";
        script.async = true;
        document.getElementById("jotform-container")?.appendChild(script);
    }, []);

    return (
        <div className="container" id="eviction-start">
            <div id="jotform-container"></div> {/* JotForm will load here */}
        </div>
    );
};
