import React from 'react';

class CTA extends React.Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="call-section1">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="left-call">
                                        <h2>For More Information</h2>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="right-button">
                                        <div className="btns-box">
                                            <a className="btn-style-three theme-btn" href="tel:1-844-453-2776" id="round-button"><span className="txt">CALL US</span></a>
                                        </div>
                                        <div className="btns-box ml-3">
                                            <a className="btn-style-three theme-btn" href="/services/eviction/start-eviction-online/" id="round-button"><span className="txt">START NOW</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default CTA;
