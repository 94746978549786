import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";


const Header = ({ className, navPosition, hideNav, bottomOuterDivider, bottomDivider, type, pageTitle, active, ...props }) => {
	useEffect(() => {
		(function($) {
			"use strict";
			//Hide Loading Box (Preloader)
			function handlePreloader() {
				if($('.loader-wrap').length){
					$('.loader-wrap').delay(1000).fadeOut(500);
				}
			}
			if ($(".preloader-close").length) {
				$(".preloader-close").on("click", function(){
					$('.loader-wrap').delay(200).fadeOut(500);
				})
			}
			//Update Header Style and Scroll to Top
			function headerStyle() {
				if($('.main-header').length){
					var windowpos = $(window).scrollTop();
					var siteHeader = $('.main-header');
					var scrollLink = $('.scroll-to-top');
					
					var HeaderHight = $('.main-header').height();
					if (windowpos >= HeaderHight) {
						siteHeader.addClass('fixed-header');
						scrollLink.fadeIn(300);
					} else {
						siteHeader.removeClass('fixed-header');
						scrollLink.fadeOut(300);
					}
					
				}
			}
			headerStyle();
			$(window).on('scroll', function() {
				headerStyle();
			});
			//Submenu Dropdown Toggle
			if($('.main-header li.dropdown ul').length){
				$('.main-header li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>');
				
				//Dropdown Button
				$('.main-header li.dropdown .dropdown-btn').on('click', function() {
					$(this).prev('ul').slideToggle(500);
				});
				
				//Dropdown Menu / Fullscreen Nav
				$('.fullscreen-menu .navigation li.dropdown > a').on('click', function() {
					$(this).next('ul').slideToggle(500);
				});
				
				//Disable dropdown parent link
				// $('.navigation li.dropdown > a').on('click', function(e) {
				// 	e.preventDefault();
				// });
				
				//Disable dropdown parent link
				// $('.main-header .navigation li.dropdown > a,.hidden-bar .side-menu li.dropdown > a').on('click', function(e) {
				// 	e.preventDefault();
				// });
				
			}
			//Mobile Nav Hide Show
			if($('.mobile-menu').length){
				var mobileMenuContent = $('.main-header .nav-outer .main-menu').html();
				//$('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);
				//$('.sticky-header .main-menu').append(mobileMenuContent);
				
				//Dropdown Button
				$('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
					$(this).toggleClass('open');
					$(this).prev('ul').slideToggle(500);
				});
				//Menu Toggle Btn
				$('.mobile-nav-toggler').on('click', function() {
					$('body').addClass('mobile-menu-visible');
				});
		
				//Menu Toggle Btn
				$('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function() {
					$('body').removeClass('mobile-menu-visible');
				});
				
			}
			if($('.search-box-outer').length) {
				$('.search-box-outer').on('click', function() {
					$('body').addClass('search-active');
				});
				$('.close-search').on('click', function() {
					$('body').removeClass('search-active');
				});
			}
			$('.navSidebar-button').click(function(){
				$('.xs-sidebar-group').addClass('isActive');
			});
		
		})(window.jQuery);
		
	});
	return (
		<>
			<header class="main-header">
				<div class="header-top">
					<div class="auto-container">
						<div class="inner-container clearfix">
						<div class="top-left clearfix">
							<ul class="page-links">
								<React.Fragment>
									<li><Link to="/faq">FAQ</Link></li>
									<li><Link to="/privacy-policy">Privacy Policy</Link></li>
									<li><Link to="/terms-and-conditions">Term Of Service</Link></li>
								</React.Fragment>
							</ul>
						</div>
							<div class="top-right pull-right clearfix">
								<ul class="social-box">
									<li class="follow">Follow us:</li>
									<li><a href="https://www.facebook.com/LdaProLegal/" class="fa fa-facebook" target="_Blank" rel="noreferrer"></a></li>
									<li><a href="https://www.instagram.com/ldapro_legal/" class="fa fa-instagram" target="_Blank" rel="noreferrer"></a></li>
									<li><a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x809ad794cb08988b:0xf26331dc35857cf?source=g.page.m" class="fa fa-google" target="_Blank" rel="noreferrer"></a></li>
									<li><a href="https://www.yelp.com/biz/lda-pro-legal-document-assistants-sacramento-3" class="fa fa-yelp" target="_Blank" rel="noreferrer"></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="header-upper">
					<div class="auto-container">
						<div class="clearfix">
							<div class="pull-left logo-box">
								<div class="logo"><Link to="/"><img src={require("../../assets/images/common/lda-pro-new-logo.webp")} alt="LDA PRO LEGAL" title=""/></Link></div>
							</div>
							<div class="pull-right upper-right clearfix">
								<div class="upper-column info-box">
									<div class="icon-box"><span class="flaticon-email"></span></div>
									<ul>
										<li>Email Us At:<br/> <a href="mailto:Contact@Ldaprolegal.Com">Contact@Ldaprolegal.Com</a></li>
									</ul>
								</div>
								<div class="upper-column info-box">
									<div class="icon-box"><span class="flaticon-map"></span></div>
									<ul>
										<li>Main Office:<br/> <a href="tel:(916) 620-2446">(916) 620-2446</a></li>
									</ul>
								</div>
								<div class="upper-column info-box">
									<div class="icon-box"><span class="flaticon-telephone"></span></div>
									<ul>
										<li>Toll Free:<br/> <a href="tel:1-844-453-2776">1-844-453-2776</a></li>
									</ul>
								</div>
								<div class="upper-column info-box">
									<Link to="https://ldaprolegal.com/contact-us/" class="theme-btn btn-style-three"><span class="txt">Get a Quote</span></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			
				<div class="header-lower">
				<div class="auto-container clearfix">
					
				<div class="nav-outer clearfix">
				<div class="curve-layer"></div>
				<div class="mobile-nav-toggler"><span class="icon flaticon-menu"></span></div>
				<React.Fragment>
					<nav class="main-menu navbar-expand-md">
						<div class="navbar-header">  
						<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
						</button>
						</div>
						
						<div class="navbar-collapse collapse clearfix" id="navbarSupportedContent">
							<ul id="menu-main-menu" class="navigation clearfix">
								<li>
									<Link to="/">Home</Link>
								</li>
								<li><Link to="/about-us">About Us</Link></li>
								<li class="dropdown">
									<Link to="/services" data-toggle="dropdown" class="dropdown-toggle">Services</Link>
									<ul role="menu" class="dropdown-menu">
										<li><Link to="/services/eviction">Eviction</Link></li>
										<li><Link to="/services/divorce">Divorce</Link></li>
										<li><Link to="/services/probate">Probate</Link></li>
										<li><Link to="/services/living-trust">Living Trust</Link></li>
										<li><Link to="/services/deed">Deed Services</Link></li>
									</ul>
								</li>
								<li class="dropdown">
									<Link to="/areas" data-toggle="dropdown" class="dropdown-toggle" aria-haspopup="true">Areas</Link>
									<ul role="menu" class="dropdown-menu">
										<li><Link to="/areas/sacramento">Sacramento</Link></li>
										<li><Link to="/areas/placer">Placer</Link></li>
										<li><Link to="/areas/yolo">Yolo</Link></li>
										<li><Link to="/areas/san-joaquin">San Joaquin</Link></li>
										<li><Link to="/areas/solano">Solano</Link></li>
										<li><Link to="/areas/alameda">Alameda</Link></li>
										<li><Link to="/areas/butte">Butte</Link></li>
										<li><Link to="/areas/amador">Amador</Link></li>
										<li><Link to="/areas/contra-costa">Contra Costa</Link></li>
										<li><Link to="/areas/el-dorado">El Dorado</Link></li>
										<li><Link to="/areas/marin">Marin</Link></li>
										<li><Link to="/areas/napa">Napa</Link></li>
										<li><Link to="/areas/san-fransisco">San Fransisco</Link></li>
										<li><Link to="/areas/san-mateo">San Mateo</Link></li>
										<li><Link to="/areas/santa-clara">Santa Clara</Link></li>
										<li><Link to="/areas/shasta">Shasta</Link></li>
										<li><Link to="/areas/sutter">Sutter</Link></li>
										<li><Link to="/areas/yuba">Yuba</Link></li>
									</ul>
								</li>
								<li><Link to="/blogs">Blogs</Link></li>
								<li><Link to="/contact-us">Contact Us</Link></li>
							</ul>              
						</div>
					</nav>
				</React.Fragment>
				<div class="outer-box clearfix">
					<div class="search-box-btn search-box-outer"><span class="icon fa fa-search"></span></div>
					<div class="nav-btn navSidebar-button"><span class="icon flaticon-menu-2"></span></div>
				</div>
				</div>
					</div>
				</div>
				
				<div class="sticky-header">
					<div class="auto-container clearfix">
						<div class="logo pull-left">
							<Link to="/" title=""><img src={require("../../assets/images/common/logo-100.webp")} alt="LDA PRO LEGAL" title=""/></Link>
						</div>
						<div class="pull-right">
						<nav class="main-menu">
							<div class="menu-outer"></div>
							<div class="navbar-header"><button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span></button></div>
							<div class="navbar-collapse collapse clearfix" id="navbarSupportedContent">
								<ul id="menu-main-menu" class="navigation clearfix">
									<li>
										<Link to="/">Home</Link>
									</li>
									<li><Link to="/about-us">About Us</Link></li>
									<li class="dropdown">
										<Link to="/services" data-toggle="dropdown" class="dropdown-toggle">Services</Link>
										<ul role="menu" class="dropdown-menu">
											<li><Link to="/services/eviction">Eviction</Link></li>
											<li><Link to="/services/probate">Probate</Link></li>
											<li><Link to="/services/living-trust">Living Trust</Link></li>
											<li><Link to="/services/deed">Deed Services</Link></li>
											<li><Link to="/services/divorce">Divorce</Link></li>
										</ul>
									</li>
									<li class="dropdown">
										<Link to="/areas" data-toggle="dropdown" class="dropdown-toggle" aria-haspopup="true">Areas</Link>
										<ul role="menu" class="dropdown-menu">
											<li><Link to="/areas/sacramento">Sacramento</Link></li>
											<li><Link to="/areas/placer">Placer</Link></li>
											<li><Link to="/areas/yolo">Yolo</Link></li>
											<li><Link to="/areas/san-joaquin">San Joaquin</Link></li>
											<li><Link to="/areas/solano">Solano</Link></li>
											<li><Link to="/areas/alameda">Alameda</Link></li>
											<li><Link to="/areas/butte">Butte</Link></li>
											<li><Link to="/areas/amador">Amador</Link></li>
											<li><Link to="/areas/contra-costa">Contra Costa</Link></li>
											<li><Link to="/areas/el-dorado">El Dorado</Link></li>
											<li><Link to="/areas/marin">Marin</Link></li>
											<li><Link to="/areas/napa">Napa</Link></li>
											<li><Link to="/areas/san-fransisco">San Fransisco</Link></li>
											<li><Link to="/areas/san-mateo">San Mateo</Link></li>
											<li><Link to="/areas/santa-clara">Santa Clara</Link></li>
											<li><Link to="/areas/shasta">Shasta</Link></li>
											<li><Link to="/areas/sutter">Sutter</Link></li>
											<li><Link to="/areas/yuba">Yuba</Link></li>
										</ul>
									</li>
									<li><Link to="/blogs">Blogs</Link></li>
									<li><Link to="/contact-us">Contact Us</Link></li>
								</ul>
							</div>
						</nav>
							<div class="mobile-nav-toggler"><span class="icon flaticon-menu"></span></div>
						</div>
					</div>
				</div>
			
				<div class="mobile-menu">
					<div class="menu-backdrop"></div>
					<div class="close-btn"><span class="icon flaticon-multiply"></span></div>
					<React.Fragment>
						<nav class="menu-box">
							<div class="nav-logo"><Link to="/"><img src={require("../../assets/images/common/LDA-logo.webp")} alt="LDA PRO LEGAL" title=""/></Link></div>
							<div class="menu-outer">
								<ul id="menu-main-menu" class="navigation clearfix">
										<li><Link to="/">Home</Link></li>
										<li><Link to="/about-us">About Us</Link></li>
										<li><Link to="/services">Services</Link></li>
										<li><Link to="/services/eviction">Eviction</Link></li>
										<li><Link to="/services/probate">Probate</Link></li>
										<li><Link to="/services/living-trust">Living Trust</Link></li>
										<li><Link to="/services/deed">Deed Services</Link></li>
										<li><Link to="/services/divorce">Divorce</Link></li>
										<li><Link to="/areas">Areas</Link></li>
										<li><Link to="/areas/sacramento">Sacramento</Link></li>
										<li><Link to="/areas/placer">Placer</Link></li>
										<li><Link to="/areas/yolo">Yolo</Link></li>
										<li><Link to="/areas/san-joaquin">San Joaquin</Link></li>
										<li><Link to="/areas/solano">Solano</Link></li>
										<li><Link to="/areas/alameda">Alameda</Link></li>
										<li><Link to="/areas/butte">Butte</Link></li>
										<li><Link to="/areas/amador">Amador</Link></li>
										<li><Link to="/areas/contra-costa">Contra Costa</Link></li>
										<li><Link to="/areas/el-dorado">El Dorado</Link></li>
										<li><Link to="/areas/marin">Marin</Link></li>
										<li><Link to="/areas/napa">Napa</Link></li>
										<li><Link to="/areas/san-fransisco">San Fransisco</Link></li>
										<li><Link to="/areas/san-mateo">San Mateo</Link></li>
										<li><Link to="/areas/santa-clara">Santa Clara</Link></li>
										<li><Link to="/areas/shasta">Shasta</Link></li>
										<li><Link to="/areas/sutter">Sutter</Link></li>
										<li><Link to="/areas/yuba">Yuba</Link></li>
										<li><Link to="/blogs">Blogs</Link></li>
										<li><Link to="/contact-us">Contact Us</Link></li>
									</ul> 				
							</div>
						</nav>
					</React.Fragment>
				</div>
			</header>
			<div class="xs-sidebar-group info-group">
				<div class="xs-overlay xs-bg-black"></div>
				<div class="xs-sidebar-widget">
					<div class="sidebar-widget-container">
						<div class="widget-heading"><a href="#" class="close-side-widget">X</a></div>
						<div class="sidebar-textwidget">
							<div class="sidebar-info-contents">
								<div class="content-inner">
									<div class="logo">
										<Link to="/"><img src={require("../../assets/images/common/footer-lda-white-logo.webp")} alt="LDA PRO LEGAL" /></Link>
									</div>
									<div class="content-box">
										<h4>About Us</h4>
										<p class="text">We Prepare, file, And Serve Legal Documents Such as: Eviction, Probate, Living Trust, And More.</p>
														<Link to="https://ldaprolegal.com/contact-us/" class="theme-btn btn-style-one"><span class="txt">Contact Us</span></Link>
									</div>
									<div class="contact-info">
										<h4>Contact Info</h4>
										<ul class="list-style-one">
										<li><span class="icon fa fa-location-arrow"></span>3550 Watt Ave., Suite 140, Sacramento, CA 95821</li>
										<li><span class="icon fa fa-phone"></span><a href="tel:(916) 620-2446">(916) 620-2446</a></li>
										<li><span class="icon fa fa-phone"></span><a href="tel:1-844-453-2776">1-844-453-2776</a></li>
										<li><span class="icon fa fa-envelope"></span><a href="mailto:contact@ldaprolegal.com">contact@ldaprolegal.com</a></li>
										<li><span class="icon fa fa-clock-o"></span>Week Days: 09.00 to 18.00 Sunday: Closed</li>
										</ul>
									</div>
									<ul class="social-box">
										<li><a href="https://www.facebook.com/LdaProLegal/" class="fa fa-facebook" target="_Blank" rel="noreferrer"></a></li>
										<li><a href="https://www.instagram.com/ldapro_legal/" class="fa fa-instagram" target="_Blank" rel="noreferrer"></a></li>
										<li><a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x809ad794cb08988b:0xf26331dc35857cf?source=g.page.m" class="fa fa-google" target="_Blank" rel="noreferrer"></a></li>
										<li><a href="https://www.yelp.com/biz/lda-pro-legal-document-assistants-sacramento-3" class="fa fa-yelp" target="_Blank" rel="noreferrer"></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{ type == 'page' ?
				<div class="container-fluid " id="inner-banner">
					<div class="container">
						<div class="row">
							<div class="col-xl-12">
								<div class="about-content">
									<div class="breadcrumbs">
										<h1>{pageTitle}</h1>
										<ul><li><Link to="/">Home</Link></li><li>|</li><li>{pageTitle}</li></ul>
									</div>
								</div>
							</div>
						</div>
					</div>	
				</div>
				: ''
			}
		</>
	);
};

export default Header;
