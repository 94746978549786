import React, {useEffect} from "react";
import {Link} from 'react-router-dom'
import ContactSection from '../components/common/ContactSection'
import Testimonials from "../components/common/Testimonials"

export const VideoGallery = (): React.ReactElement<any, any> => {
    useEffect(() => {
        //jquery code goes here
    });

    return (
        <>
            <div className="container bg-white" id="about_section">
                <div className="row mb-5">
                    <div className="col-md-6 text-center">
                        <video src={require("../assets/videos/eviction.mp4")} style={{width:"100%"}} controls/>
                        <h3>LDA PRO Eviction Services</h3>
                    </div>
                    <div className="col-md-6 text-center">
                        <video src={require("../assets/videos/living-trust.mp4")} style={{width:"100%"}} controls/>
                        <h3>LDA PRO Living Trust Services</h3>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-6 text-center">
                        <video src={require("../assets/videos/divorce.mp4")} style={{width:"100%"}} controls/>
                        <h3>LDA PRO Divorce Services</h3>
                    </div>
                    <div className="col-md-6 text-center">
                        
                    </div>
                </div>
            </div>

            <ContactSection/>
        </>
    );
};
