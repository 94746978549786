import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const testimonials = {
    loop:true,
    margin:0,
    nav:true,
    rtl:false,
    smartSpeed: 1000,
    navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        800:{
            items:1
        },
        1024:{
            items:1
        },
        1200:{
            items:1
        }
    }
  }
class Testimonials extends React.Component {

    render() {
        return (
            <OwlCarousel className="single-item-carousel owl-carousel owl-theme" id="testimonial" {...testimonials}>
                <div className="item">
                    <div className="user">
                        <img src={require("../../assets/images/home/user.webp")} className="img-fluid"/>
                    </div>
                    <p>“Angela Jones is great she always had time for me to answer all my questions. She helped me get my property back. I recommend her, her service is 10+, Thank you Angela.” </p>
                    <span className="user-name">
                    Bobbie Fagan	
                    </span>
                </div>
                <div className="item">
                    <div className="user">
                        <img src={require("../../assets/images/home/user2.webp")} className="img-fluid"/>
                    </div>
                    <p>“Excellent and responsive service that is above and beyond. Highly recommend!"</p>
                    <span className="user-name">
                    Vijay Kumar	
                    </span>
                </div>
                <div className="item">
                    <div className="user">
                        <img src={require("../../assets/images/home/user3.webp")} className="img-fluid"/>
                    </div>
                    <p>“I called late in the afternoon for services. Asked a few questions and was given very clear instructions to proceed with services. The following day , my papers were served to said person. Not only was she fast, she was kind. I highly recommend LDA Pro Legal to anyone seeking assistance.” </p>
                    <span className="user-name">
                    Jacque Bell	
                    </span>
                </div>
            </OwlCarousel>
        );
    }
}


export default Testimonials;
