import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";



export const Fourzerofour = (): React.ReactElement => {
    return (
        <>
            <div className="error-page">
                <main className="container">
                    <h1>404</h1>
                    <h4>It seems like the page you are looking for is not found.</h4>
                    <br/>
                    <br/>
                    <p className="btns-box">
                        <React.Fragment>
                            <Link to="/" className="btn-style-three theme-btn" >Go Back to Home</Link>
                        </React.Fragment>
                    </p>
                </main>
            </div>
        </>
    );
};
